import { Select, Space, Tag } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { hosts, selectedHosts, toggleHostSelection, updateHostsSelection } from "../slice";
import styles from "./HostsFilter.module.css";
import { useEffect } from "react";
import { removeAccents } from "../../../../app/utils";
import __ from "../../../../app/i18n";
const { CheckableTag } = Tag;

export default function HostsFilter() {
  const currentHosts = useSelector(selectedHosts);
  const allHosts = useSelector(hosts);
  const appliedHosts = useSelector(selectedHosts);
  const dispatch = useDispatch();

  const isSelected = (host) => {
    return currentHosts.includes(host.id);
  };

  const handleChange = (host) => {
    dispatch(toggleHostSelection(host.id));
  };

  const handleOnChange = (hosts) => {
    dispatch(updateHostsSelection(hosts));
  };

  const options = (allHosts || []).map((item, index) => ({
    key: `host#${index}`,
    label: item.name,
    value: item.id,
  }));

  const selectProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    options,
    value: appliedHosts,
    onChange: (e) => handleOnChange(e),
    placeholder: __('meetings.filters.selectHost'),
    showArrow: true,
    filterOption: (typed, item) => {
      const contains = removeAccents(String(item.label).toUpperCase()).startsWith(String(removeAccents(typed)).toUpperCase())
      return contains;
    }
  };

  return (
    <Space
      direction="vertical"
      style={{
        width: "calc(100% - 30px)",
        margin: "15px",
      }}
    >
      <Select {...selectProps}/>
    </Space>
  );
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../../../app/api";
import __ from "../../../../app/i18n";
import {
  showGlobalErrors,
  showGlobalSuccess,
  startLoading,
  stopLoading,
} from "../../../loading/slice";

const initialState = {
  status: "iddle",
  isFetchingPassword: false,
  isPasswordChangeModalOpen: false,
};

export const changePassword = createAsyncThunk(
  "profile/changePassword",
  async (payload, { dispatch }) => {
    let response;
    response = await API("changePassword", payload);

    if (response) {
      dispatch(showGlobalSuccess([__("generic.alerts.passwordChangeSuccess")]));
      dispatch(closeChangePasswordModal());
    } else {
      dispatch(showGlobalErrors([{ reason: 'invalid_password' }]));
    }

    dispatch(stopLoading());
  }
);

export const resetPasswordByEmail = createAsyncThunk(
  "profile/resetPassword",
  async (payload, { dispatch }) => {
    dispatch(startLoading());

    const response = await API("resetPasswordByEmail", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    } else {
      dispatch(showGlobalSuccess([__("generic.alerts.resetedPassword")]));
    }

    dispatch(stopLoading());

    return response;
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    startFetchingPassword: (state) => state.isFetchingPassword = true,
    stopFetchingPassword: (state) => state.isFetchingPassword = false,
    openChangePasswordModal: (state) => {
      state.isPasswordChangeModalOpen = true;
    },
    closeChangePasswordModal: (state) => {
      state.isPasswordChangeModalOpen = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state) => {
        state.isFetchingPassword = true;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.isFetchingPassword = false;
      })
      .addCase(changePassword.rejected, (state) => {
        state.isFetchingPassword = false;
      })
  }
});

export const { openChangePasswordModal, closeChangePasswordModal, startFetchingPassword, stopFetchingPassword } = profileSlice.actions;

export const selectIsFetching = (state) => state.profile.status === "loading";
export const selectIsFetchingPassword = (state) => state.profile.isFetchingPassword;
export const selectUsers = (state) => state.profile.users;
export const selectIsChangePasswordModal = (state) => state.profile.isPasswordChangeModalOpen;

export default profileSlice.reducer;

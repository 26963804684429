import Modal from "antd/lib/modal/Modal";
import { useEffect, useState } from "react";
import Api from "../../app/api";
import { Alert, Form, Input, message, DatePicker } from "antd";
import { copyTextToClipboard } from "../../app/utils";
import { CopyOutlined } from "@ant-design/icons";
import moment from "moment";

const ShareMeetingModal = ({ open, onClose, meeting }) => {
  const [shareId, setShareId] = useState();
  const [shareUrl, setShareUrl] = useState();
  const [dueDateStr, setDueDateStr] = useState();
  const [messageApi, contextHolder] = message.useMessage();

  const getShareableUrl = (shareId) => "https://web.samu.ai/share/" + shareId;
  const handleCopy = () => {
    copyTextToClipboard(getShareableUrl(shareId));

    messageApi.open({
      type: "success",
      content: "Copiado al portapapeles",
    });
  };

  const handleUpdateShareDueDate = (date) => {
    setDueDateStr(date);

    Api('updateShareableUrl', { id: shareId, dueDate: date });
  }


  useEffect(() => {
    async function fetch() {
      const { status, id, dueDate } = await Api("getShareableUrl", {
        id: meeting.id,
      });
      if (status !== "ERROR") {
        const url = getShareableUrl(id);

        copyTextToClipboard(url);
        setShareUrl(url);
        setShareId(id);
        setDueDateStr(moment(dueDate));
      }
    }

    if (open) {
      fetch();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onOk={() => {
        handleCopy();
        onClose();
      }}
      closable={false}
      cancelButtonProps={{ show: false }}
      okText="Copiar Link"
      cancelText="Cerrar"
      title="Compartir"
    >
      {contextHolder}
      <Alert
        message={
          <>
            El siguiente link es publico y será accesible hasta la fecha que selecciones.
          </>
        }
      />
      <Form layout="vertical">
        <Form.Item label="Link" style={{ marginTop: "10px", marginBottom: "0" }}>
          <Input
            value={shareUrl}
            addonAfter={
              <CopyOutlined
                style={{
                  fontSize: "18px",
                  cursor: "pointer",
                }}
                onClick={handleCopy}
              />
            }
          />
        </Form.Item>
        <Form.Item label="Fecha de expiración" style={{ marginTop: "10px", marginBottom: "0" }}>
          <DatePicker
            value={dueDateStr}
            onChange={(e) => handleUpdateShareDueDate(e)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ShareMeetingModal;

import { Drawer, Button, Form, Input, Select, Checkbox } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import __ from "../../../app/i18n";
import { createTeam } from "./slice";
import { selectUsers } from "../users/slice";
import { removeAccents } from "../../../app/utils";

export default function NewTeam({ open, onClose }) {
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const [form] = Form.useForm();
  const [selectAllUsers, setSelectAllUsers] = useState(true);
  const [selectAllManagers, setSelectAllManagers] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState(
    users.map((user) => user.id)
  );
  const [selectedManagers, setSelectedManagers] = useState(
    users.filter(user => user.role === 2).map((user) => user.id)
  );

  const handleNewTeam = (values) => {
    const params = {
      name: values.name,
      users: selectAllUsers ? users.map((user) => user.id) : selectedUsers,
      managers: selectAllManagers
        ? users.filter(user => user.role === 2).map((user) => user.id)
        : selectedManagers,
    };
    dispatch(createTeam(params));
    setSelectAllManagers(true);
    setSelectAllUsers(true);
    setSelectedManagers(users.filter(user => user.role === 2).map((user) => user.id));
    setSelectedUsers(users.map((user) => user.id));
    form.resetFields();
    onClose();
  };

  const handleSelectAllUsersChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAllUsers(isChecked);
    setSelectedUsers(isChecked ? users.map((user) => user.id) : []);
  };

  const handleSelectAllManagersChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAllManagers(isChecked);
    setSelectedManagers(isChecked ? users.filter(user => user.role === 2).map((user) => user.id) : []);
  };

  return (
    <Drawer
      title={__("team.newTeam.label")}
      zIndex={999}
      placement={"right"}
      width={500}
      onClose={onClose}
      open={open}
      getContainer={true}
    >
      <Form
        form={form}
        name="newTeam"
        layout="vertical"
        onFinish={handleNewTeam}
        autoComplete="off"
      >
        <Form.Item
          name="name"
          label={__("generic.fields.name")}
          rules={[{ required: true, message: __("generic.required.name") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item style={{ marginBottom: "2px" }}>
          <Checkbox
            checked={selectAllUsers}
            onChange={handleSelectAllUsersChange}
          >
            {__("team.newTeam.selectAllUsers")}
          </Checkbox>
        </Form.Item>

        {!selectAllUsers && (
          <>
            <Form.Item
              name="users"
              label={__("team.newTeam.selectUsers")}
              rules={[
                {
                  required: !selectAllUsers,
                  message: __("team.newTeam.requiredUsers"),
                },
              ]}
            >
              <Select
                mode="multiple"
                value={selectedUsers}
                disabled={selectAllUsers}
                onChange={(value) => setSelectedUsers(value)}
                showArrow
                options={(users || []).map((user, index) => ({
                  key: `user#${user.id}`,
                  label: user.name,
                  value: user.id,
                }))}
                filterOption={(typed, item) => {
                  const contains = String(item.label)
                    .toUpperCase()
                    .startsWith(String(removeAccents(typed)).toUpperCase());
                  return contains;
                }}
              />
            </Form.Item>
          </>
        )}

        <Form.Item style={{ marginBottom: "2px" }}>
          <Checkbox
            checked={selectAllManagers}
            onChange={handleSelectAllManagersChange}
          >
            {__("team.newTeam.selectAllManagers")}
          </Checkbox>
        </Form.Item>

        {!selectAllManagers && (
          <Form.Item
            name="managers"
            label={__("team.newTeam.selectManagers")}
            rules={[
              {
                required: !selectAllManagers,
                message: __("team.newTeam.requiredManagers"),
              },
            ]}
          >
            <Select
              mode="multiple"
              value={selectedManagers}
              disabled={selectAllManagers}
              onChange={(value) => setSelectedManagers(value)}
              options={(users || [])
                    .filter((u) => u.role === 2)
                    .map((user, index) => ({
                      key: `user#${user.id}`,
                      label: user.name,
                      value: user.id,
                    }))}
                  filterOption={(typed, item) => {
                    const contains = String(item.label)
                      .toUpperCase()
                      .startsWith(String(removeAccents(typed)).toUpperCase());
                  return contains;
                }}
            />
          </Form.Item>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {__("meetings.filters.save")}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}

const methods = {
    getTeams: {
      path: "/teams/list",
      method: "GET",
    },
    createTeam: {
      path: "/teams/new",
      method: "POST",
    },
    updateTeam: {
      path: ({id}) => `/teams/${id}`,
      method: "PUT",
    },
    deleteTeam: {
      path: ({id}) => `/teams/${id}`,
      method: "DELETE"
    }
}
export default methods;
import { Tag } from "antd";
import React from "react";
import styles from "../meetings/meetings.module.css";
import { Phone } from "tabler-icons-react";

const MeetingTags = ({ meeting }) => {
  let { tags, callType } = meeting;

  return (
    <>
      {tags ? (
        <span>
          {(tags || []).map((tag) => (
            <Tag className={styles.meetingTag} onClose={(e) => { }}>
              {tag.name}
            </Tag>
          ))}
        </span>
      ) : (
        ""
      )}
      {(callType && callType !== "undefined") ? (
        <span>
          <Tag className={styles.callTypeTag} onClose={(e) => { }}>
            <Phone size={12} style={{ position: "relative", top: "1px" }} />{" "}
            {callType}
          </Tag>
        </span>
      ) : (
        ""
      )}
    </>
  );
};
export default MeetingTags;

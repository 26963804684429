import { Drawer, Button, Form, Input, Select, Checkbox } from "antd";
import { useState, useEffect } from "react";
import __ from "../../../app/i18n";
import { updateTeam } from "./slice";
import { useSelector, useDispatch } from "react-redux";
import { selectUsers } from "../users/slice";
import { removeAccents } from '../../../app/utils';

export default function EditTeam({ open, onClose, team }) {
  const users = useSelector(selectUsers);
  const dispatch = useDispatch();

  const [selectAllUsers, setSelectAllUsers] = useState(false);
  const [selectAllManagers, setSelectAllManagers] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);

  useEffect(() => {
    if (team) {
      setSelectedUsers(team.users);
      setSelectedManagers(team.managers.filter(managerId => 
        users.find(user => user.id === managerId)?.role === 2
      ));
      setSelectAllUsers(
        team.users.length === users.length || team.users.length === 0
      );
      const availableManagers = users.filter(user => user.role === 2);
      const selectedManagerIds = team.managers.filter(managerId => 
        users.find(user => user.id === managerId)?.role === 2
      );
      setSelectAllManagers(
        selectedManagerIds.length === availableManagers.length || selectedManagerIds.length === 0
      );
    }
  }, [team, users]);

  if (!open || !team) return null;

  const handleSelectAllUsersChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAllUsers(isChecked);
    setSelectedUsers(isChecked ? users.map((user) => user.id) : []);
  };

  const handleSelectAllManagersChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAllManagers(isChecked);
    setSelectedManagers(isChecked ? users.filter(user => user.role === 2).map((user) => user.id) : []);
  };

  const handleEditTeam = (values) => {
    const params = {
      name: values.name,
      users: selectAllUsers ? users.map((user) => user.id) : selectedUsers,
      managers: selectAllManagers
        ? users.filter(user => user.role === 2).map((user) => user.id)
        : selectedManagers,
    };
    dispatch(updateTeam({ ...params, id: team._id }));
    onClose();
  };

  return (
    <Drawer
      title={__("team.editTeam")}
      zIndex={999}
      placement={"right"}
      width={500}
      onClose={onClose}
      open={open}
      getContainer={true}
    >
      <Form
        initialValues={{
          users: team.users,
          managers: team.managers.filter(managerId => 
            users.find(user => user.id === managerId)?.role === 2
          ),
        }}
        name="editTeam"
        layout="vertical"
        onFinish={handleEditTeam}
        autoComplete="off"
      >
        <Form.Item
          name="name"
          label={__("generic.fields.name")}
          initialValue={team.name}
          rules={[{ required: true, message: __("generic.required.name") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item style={{ marginBottom: "2px" }}>
          <Checkbox
            checked={selectAllUsers}
            onChange={handleSelectAllUsersChange}
          >
            {__("team.newTeam.selectAllUsers")}
          </Checkbox>
        </Form.Item>

        {!selectAllUsers && (
          <Form.Item
            name="users"
            label={__("team.newTeam.selectUsers")}
            rules={[
              {
                required: !selectAllUsers,
                message: __("team.newTeam.requiredUsers"),
              },
            ]}
          >
            <Select
              mode="multiple"
              value={selectedUsers}
              disabled={selectAllUsers}
              onChange={(value) => setSelectedUsers(value)}
              options={ (users || []).map((user, index) => ({
                key: `user#${user.id}`,
                label: user.name,
                value: user.id,
              }))}
              filterOption={(typed, item) => {
                const contains = String(item.label).toUpperCase().startsWith(String(removeAccents(typed)).toUpperCase())
                return contains;
              }}
            />
          </Form.Item>
        )}

        <Form.Item style={{ marginBottom: "2px" }}>
          <Checkbox
            checked={selectAllManagers}
            onChange={handleSelectAllManagersChange}
          >
            {__("team.newTeam.selectAllManagers")}
          </Checkbox>
        </Form.Item>

        {!selectAllManagers && (
          <Form.Item
            name="managers"
            label={__("team.newTeam.selectManagers")}
            rules={[
              {
                required: !selectAllManagers,
                message: __("team.newTeam.requiredManagers"),
              },
            ]}
        >
          <Select
            mode="multiple"
            value={selectedManagers}
            disabled={selectAllManagers}
            onChange={(value) => setSelectedManagers(value)}
            options={ (users || []).filter(u => u.role === 2).map((user, index) => ({
              key: `user#${user.id}`,
              label: user.name,
              value: user.id,
            }))}
            filterOption={(typed, item) => {
              const contains = String(item.label).toUpperCase().startsWith(String(removeAccents(typed)).toUpperCase())
              return contains;
            }}
            />
          </Form.Item>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {__("meetings.filters.save")}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}

import { Drawer, Button, Form, Input, Checkbox, Select, Tag } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUser } from "./slice";
import { selectTeams } from "../teams/slice";
import __ from "../../../app/i18n";
import { removeAccents } from "../../../app/utils";

export default function NewTeamUser({ open, onClose }) {
  const dispatch = useDispatch();
  const teams = useSelector(selectTeams);
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(false);
  const [selectAllTeams, setSelectAllTeams] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState([]);

  const handleSelectAllTeamsChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAllTeams(isChecked);
    const newTeams = isChecked ? teams.map(team => team._id) : [];
    setSelectedTeams(newTeams);
  };

  const handleTeamsChange = (value) => {
    setSelectedTeams(value);
    setSelectAllTeams(value.length === teams.length);
  };

  const handleNewUser = (values) => {
    dispatch(createUser({ 
      ...values,
      sendWelcomeEmail,
      teams: selectAllTeams ? teams.map(team => team._id) : selectedTeams 
    }));
  };

  return (
    <Drawer
      title={__("team.list.newUser")}
      placement={"right"}
      width={500}
      onClose={() => onClose()}
      open={open}
      getContainer={true}
    >
      <Form
        name="basic"
        layout="vertical"
        onFinish={handleNewUser}
        autoComplete="off"
      >
        <Form.Item
          name="name"
          label={__("generic.fields.name")}
          rules={[
            {
              required: true,
              message: __("generic.required.name"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="lastName"
          label={__("generic.fields.lastName")}
          rules={[
            {
              required: true,
              message: __("generic.required.lastName"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              message: __("generic.required.email"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item style={{ marginBottom: "2px" }}>
          <Checkbox
            checked={selectAllTeams}
            onChange={handleSelectAllTeamsChange}
          >
            {__("team.newTeam.selectAllTeams")}
          </Checkbox>
        </Form.Item>

        <Form.Item
          name="teams"
          label={__("team.teams")}
        >
          <Select
            mode="multiple"
            disabled={selectAllTeams}
            value={selectedTeams}
            onChange={handleTeamsChange}
            style={{ width: '100%' }}
            optionLabelProp="label"
            filterOption={(typed, item) => {
              const contains = String(item.label)
                .toUpperCase()
                .startsWith(String(removeAccents(typed)).toUpperCase());
              return contains;
            }}
          >
            {teams.map(team => (
              <Select.Option 
                key={team._id} 
                value={team._id}
                label={team.name}
              >
                <Tag color={team.color || 'blue'}>{team.name}</Tag>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={__("generic.fields.sendWelcomeEmail")}
          name="sendWelcomeEmail"
        >
          <Checkbox name="sendWelcomeEmail" onChange={(e) => setSendWelcomeEmail(e.target.checked)}/>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {__("generic.fields.createUser")}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}

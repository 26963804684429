import { Input, Modal, Select } from "antd";
import React, { useState } from "react";
import Api from "../../app/api";
import __ from "../../app/i18n";
import { appMeetingLangs } from "../../app/utils";

const NewMeetingModal = ({ isModalOpen, close }) => {
  const [conferenceId, setConferenceId] = useState("");
  const [lang, setLang] = useState("MEXICO");
  const [loading, setLoading] = useState(false);

  const handleOnChange = (v) => {
    if (String(v).includes("meet.google.com/")) {
      let conferenceId = String(v).split("/").pop();
      if (conferenceId.includes("?")) {
        conferenceId = String(conferenceId).split("?")[0];
      }

      setConferenceId(conferenceId);
    } else if (String(v).includes('teams.microsoft.com')) {
      setConferenceId(v);
    } else {
      setConferenceId(null);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    await Api("joinToMeeting", { conferenceId, lang });
    setLoading(false);
    close();
  };

  return (
    <>
      <Modal
        title="Unirse a videollamada"
        open={isModalOpen}
        okButtonProps={{ disabled: !conferenceId }}
        onOk={handleSubmit}
        onCancel={close}
        okText="Unirse"
        cancelText="Cancelar"
        confirmLoading={loading}
      >
        <Select
          value={lang}
          onChange={(e) => {
            setLang(e);
          }}
          style={{ minWidth: "10px", display: "inline-block" }}
          optionLabelProp="label"
        >
          {appMeetingLangs.map((lang) => (
            <Select.Option 
              key={lang.value} 
              value={lang.value}
              label={<span className={`flag flag-country-${lang.flag}`}></span>}
            >
              <span className={`flag flag-country-${lang.flag}`}></span>
            </Select.Option>
          ))}
        </Select>
        <Input
          placeholder="https://meet.google.com/msb-truy-ttu"
          onChange={(e) => handleOnChange(e.target.value)}
          style={{
            display: "inline-block",
            borderLeft: "none",
            maxWidth: "80%",
          }}
        />
      </Modal>
    </>
  );
};

export default NewMeetingModal;

import { Select, Space, Tag } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { selectedSources, updateSourcesSelection } from "../slice";
import { removeAccents } from "../../../../app/utils";
import __ from "../../../../app/i18n";

export default function SourceFilter() {
  const currentHosts = useSelector(selectedSources);
  const appliedHosts = useSelector(selectedSources);
  const dispatch = useDispatch();



  const handleOnChange = (hosts) => {
    dispatch(updateSourcesSelection(hosts));
  };

  const sources = [
    { id: 'GOOGLE', name: 'Google Meet' },
    { id: 'MICROSOFT', name: 'Microsoft Teams' },
    { id: 'WHATSAPP', name: 'WhatsApp' },
    { id: 'HUBSPOT', name: 'HubSpot Calling'}
  ];

  const options = sources.map((item, index) => ({
    key: `source#${index}`,
    label: item.name,
    value: item.id,
  }));

  const selectProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    options,
    value: appliedHosts,
    onChange: (e) => handleOnChange(e),
    placeholder: __('meetings.filters.selectSource'),
    showArrow: true,
    filterOption: (typed, item) => {
      const contains = String(item.label).toUpperCase().startsWith(String(removeAccents(typed)).toUpperCase())
      return contains;
    }
  };

  return (
    <Space
      direction="vertical"
      style={{
        width: "calc(100% - 30px)",
        margin: "15px",
      }}
    >
      <Select {...selectProps}/>
    </Space>
  );
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../../app/api';
import moment from 'moment';

const initialState = {
  status: 'iddle',
  conversation: {},
};

export const loadConversation = createAsyncThunk(
  'conversation/fetchConversation',
  async (id) => {
    const response = await API('getConversationDetails', { id });

    return response;
  }
);

export const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    clearConversationData: (state) => {
      state.conversation = {};
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(loadConversation.pending, (state) => {
        state.status = 'loading';
        state.conversation = {};
      })
      .addCase(loadConversation.fulfilled, (state, action) => {
        state.status = 'idle';
        state.conversation = action.payload;
      })
  },
});

export const selectConversation = (state) => state.conversation?.conversation;
export const selectIsLoading = (state) => state.conversation.status === 'loading';
export const selectMessages = (state) => state.conversation.conversation?.messages || [];
export const selectConversationDateFormated = (state) => {
  const conversation = state.conversation?.conversation;
  if (conversation) {
    return moment(conversation.dateFrom).format("Do").replace("º", "") + " de " + moment(conversation.dateFrom).format("MMMM") + " del " + moment(conversation.dateFrom).format("YYYY");
  }
}
export const { clearConversationData } = conversationSlice.actions;

export default conversationSlice.reducer;

import { Badge, Button, Switch, Tooltip } from "antd";
import styles from "./styles.module.css";
import MeetingTags from "../tags/MeetingTags";
import { selectIsFetching } from "./slice";
import { useSelector } from "react-redux";
import __ from "../../app/i18n";

export default function AppItem({
  app,
  children,
  onToggleStatus,
  canSave,
  onSave,
  canToggleStatus,
  enabled,
}) {
  const isLoading = useSelector(selectIsFetching);

  return (
    <div className={styles.libraryItemContainer} loading={isLoading}>
      <div className={styles.libraryItemBox}>
        <div className={styles.libraryHeaderContainer}>
          <img className={styles.appIcon} src={app.icon} alt="Library item" />
          <div>
            <div className="ant-card-meta-title">
              <div className="">
                {app.name}
                {app.demoUrl && (
                  <a
                    href={app.demoUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.demoLink}
                    style={{ marginLeft: "10px", fontSize: "14px" }}
                  >
                    {__("apps.generic.demo")}
                  </a>
                )}
              </div>
              <p
                className="ant-card-meta-description libraryDescription"
                style={{ fontSize: "14px", fontWeight: "inherit" }}
              >
                {app.description}
              </p>
            </div>
            <div>
              <div className={styles.saveButtonContainer}>
                <div>{children}</div>
                {canSave ? (
                  <Button
                    type="primary"
                    className={styles.saveButton}
                    onClick={() => onSave()}
                  >
                    {__("apps.extractor.buttons.save")}
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.toggleContainer}>
          <div className={styles.toggle}>
            {canToggleStatus ? (
              <Switch checked={enabled} onChange={onToggleStatus} />
            ) : (
              ""
            )}
          </div>
        </div>

        <div>
          {app.inUse ? (
            <>
              <Tooltip title="App instalada">
                <>
                  <Badge status="success" className={styles.badge} />
                </>
              </Tooltip>
              <MeetingTags meeting={{ tags: [{ name: "Instalada" }] }} />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

import { Tabs, Typography } from "antd";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import __ from "../../../../app/i18n";
import { getConfig, selectConfigFetched } from "../../../session/slice";
import { ProfileDetailTab } from "./tabs/ProfileDetailTab";
import { IntegrationsTab } from "./tabs/Integrations";
import { renderMapper } from "../../../meeting/sections/ai/tabs/utils";
import styles from "../general.module.css";

const Profile = () => {
  const dispatch = useDispatch();
  document.title = `${__("appName")} - ${__("team.menu.preferences.subMenu.general")}`;
  const configFetched = useSelector(selectConfigFetched);

  useEffect(() => {
    if (!configFetched) {
      dispatch(getConfig());
    }
  }, [configFetched]);

  const initialTabs = useMemo(() => {
    return [
      {
        label: "Perfil",
        content: <div className={styles.generalSectionsContainer}><ProfileDetailTab /></div>,
      },
      {
        label: __("menu.profile.tabs.integrations"),
        content: <div className={styles.generalSectionsContainer}><IntegrationsTab /></div>,
      },
    ];
  }, []);

  return (
    <div>
      <Typography.Title
        level={3}
        style={{ fontWeight: 400, marginTop: "2rem" }}
      >
        {__("team.menu.preferences.subMenu.general")}
      </Typography.Title>
      <div style={{ background: "#fff" }}>
          <Tabs
          tabBarStyle={{ background: "#f0f2f5" }}
            type="card"
            defaultActiveKey="0"
            tabPosition="top"
            items={renderMapper(initialTabs)}
          />
      </div>
    </div>
  );
};
export default Profile;

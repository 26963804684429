import { Badge, Button, Card, Tooltip } from "antd";
import Meta from "antd/lib/card/Meta";
import styles from "./styles.module.css";
import MeetingTags from "../tags/MeetingTags";
import { Link } from "react-router-dom";

export default function AppItem({ app }) {
  return (
    <Link to={`/apps/${app._id}`}>
      <Card className={styles.libraryItem} style={{ borderTop: "none", minHeight: "195px" }}>
        <Meta
          avatar={<img style={{ width: "50px" }} src={app.icon} />}
          title={
            <>
              <div>{app.displayName}</div>

              <div>
                {app.inUse ? (
                  <>
                    <Tooltip title="App activada">
                      <>
                        <Badge
                          status="success"
                          style={{
                            width: "25px",
                            position: "absolute",
                            right: 0,
                            top: "10px",
                            fontWeight: "10px",
                          }}
                        />
                      </>
                    </Tooltip>
                    <MeetingTags meeting={{ tags: [{ name: "Instalada" }] }} />
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          }
          description={
            <div style={{ marginTop: "15px" }}>{app.description}</div>
          }
        />
      </Card>
    </Link>
  );
}

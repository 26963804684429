import React, { useState, useEffect } from 'react';
import { Line } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import { selectUsers } from '../team/users/slice';

const AnalyticLines = ({ userAnalytics, formatter, style, className }) => {
  const [data, setData] = useState([]);
  const sellers = useSelector(selectUsers);

  useEffect(() => {
    if (userAnalytics && Array.isArray(sellers) && sellers.length) {
      const data = [];

        for (const month in userAnalytics) {
        const usersData = userAnalytics[month];

        Object.keys(usersData).forEach(userId => {
          const user = sellers.find(s => s.id === userId);
          if (user) {
            data.push({ userName: user.name, month, meetingsQty: usersData[userId] });
          }

        });
      }

      setData(data);
    }
  }, [sellers, userAnalytics]);

  const config = {
    data,
    xField: 'month',
    yField: 'meetingsQty',
    seriesField: 'userName',
    legend: {
      position: 'top',
    },
    smooth: true,
    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000,
      },
    },
    className,
    style: { ...(style || {}) }
  };

  if (formatter) {
    config.meta = {
      meetingsQty: {
        formatter: (val) => formatter(val),
      },
    };
  };

  return <Line {...config} />;
};

export default AnalyticLines;
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Line } from '@ant-design/plots';
import { getFirstAndLastDayOfWeek } from './utils/date';
import { useSelector } from 'react-redux';
import { selectUsers } from '../team/users/slice';
import moment from 'moment';

export const WeeklyLines = ({ userAnalytics, formatter }) => {
  if (!formatter) {
    formatter = v => v;
  }

  const [data, setData] = useState([]);
  const sellers = useSelector(selectUsers);

  useEffect(() => {
    if (userAnalytics && Array.isArray(sellers) && sellers.length) {
      const data = [];

      for (const weekNumber in userAnalytics) {
        const [dayFrom, dayTo] = getFirstAndLastDayOfWeek(weekNumber, 2023);
        const sellersData = userAnalytics[weekNumber];
        
        for (const sellerId in sellersData) {
          const seller = sellers.find((s) => s.id === sellerId);
          if (seller) {
            data.push({
              week: moment(dayFrom).format('DD/MM') + ' al ' + moment(dayTo).format('DD/MM'),
              key: seller.name,
              value: formatter(sellersData[sellerId])
            });
          }
         
        }
      }
      setData(data);
    }
  }, [sellers, userAnalytics]);

  const config = {
    data,
    xField: 'week',
    yField: 'value',
    seriesField: 'key',
    legend: {
      position: 'top',
    },
    // yAxis: {
    //   label: {
    //     formatter: (v) => `${(v / 10e8).toFixed(1)} B`,
    //   },
    // },

    smooth: true,
    // @TODO 后续会换一种动画方式
    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000,
      },
    },
  };

  if (formatter) {
    config.meta = {
      value: {
        formatter: (val) => formatter(val),
      },
    };
  };

  return <Line {...config} />;
};

export default WeeklyLines;
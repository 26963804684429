import Boolean from "./Boolean";
import { Typography } from "antd";

export default function Section({ name, properties, onUpdateProperty }) {
  const renderProperty = (p) => {
    let component;

    switch(p.type) {
      case 'boolean':
        component = <Boolean {...p}/>
        break;
      default:
        <span>{p.value}</span>;
        break;
    }

    return component;
  };

  const handleUpdateProperty = (propertyName, propertyValue) => {
    onUpdateProperty(name, propertyName, propertyValue)
  }

  return (
    <>
      <Typography.Title level={5}>{name}</Typography.Title>
      {properties.map(p => {
        return renderProperty({ ...p, onUpdateProperty: handleUpdateProperty })
      })}
    </>
  );
}

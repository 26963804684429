import { useSelector } from "react-redux";
import { Button, Comment, Input } from "antd";
import { useEffect, useRef, useState } from "react";
import Api from "../../../../../app/api";
import { selectUser } from "../../../../session/slice";
import { analytics } from "../../../../../hooks/tracker";
import ChatDisclaimer from "./ChatDisclaimer";
import styles from "./styles.module.css";

const ChatTab = ({ interactionId }) => {
  const [message, setMessage] = useState("");
  const user = useSelector(selectUser);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const fetch = async () => {
      const chats = await Api("getAiChats", {
        interactionId,
        reportType: "chat",
      });

      const history = chats.reduce((prev, curr, id) => {
        prev.push({ id, type: "USER", who: user.name, content: curr.question });
        prev.push({
          id,
          type: "APP",
          who: "Samu Chat Agent",
          content: curr.answer,
        });

        return prev;
      }, []);

      setComments(history);
    };

    fetch();
  }, []);

  useEffect(() => {
    // Mueve el scroll hacia el final cuando cambian los comentarios
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }, [comments]);

  const handleMessage = async () => {
    setComments((comments) => [
      ...comments,
      {
        id: comments.length + 1,
        type: "USER",
        who: user.name,
        content: message,
        createdAt: new Date(),
      },
    ]);
    setLoading(true);
    setMessage("");

    Api("chatMeeting", { interactionId, message }).then(({ content }) => {
      setComments((comments) => [
        ...comments,
        {
          id: comments.length + 1 + new Date().getTime(),
          type: "APP",
          who: "Samu Chat Agent",
          content: content.answer,
          createdAt: new Date(),
        },
      ]);
      setLoading(false);

      if (analytics) {
        analytics.track("Meeting Chat", {
          id: interactionId,
          question: content.question,
          answer: content.answer,
        });
      }
    });
  };

  return (
    <div style={{display: "flex", alignContent:"space-between", flexDirection:"column"}}>
      <ChatDisclaimer />
      <div className={styles.scrollContainer} ref={scrollContainerRef}>
      {comments.map((comment) => (
        <Comment
          className={"ai-chat-message-" + String(comment.type).toLowerCase()}
          key={`comment:${comment.id}`}
          dataId={`comment:${comment.id}`}
          author={<a>{comment.who}</a>}
          content={<p>{comment.content}</p>}
        />
      ))}
      </div>

      <Input.TextArea
        rows={2}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onPressEnter={(e) => {
          e.preventDefault();
          if (message.trim() && !loading) {
            handleMessage();
          }
        }}
      />
      <Button
        loading={loading}
        type="primary"
        style={{ width: "100%", marginTop: "15px" }}
        onClick={handleMessage}
      >
        Chat
      </Button>
    </div>
  );
};

export default ChatTab;

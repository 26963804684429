import { Layout, Button, Form, Input } from "antd";
import { loginAsExtension, loginWithGoogle } from "./slice";
import styles from "./LoggedIn.module.css";
import { useDispatch } from "react-redux";
import __ from "../../app/i18n";
import logo from "../../brand/Isologotype/samu_light_bckgrnd.png";
import GoogleLoginButton from "./GoogleLoginButton";

const { Header } = Layout;

export default function ExtensionLoginForm() {
  return (
    <Layout>
      <Header className="header">
        <div className="logo" />
      </Header>
      <div className={styles.loginFormContainer}>
        <App />
      </div>
    </Layout>
  );
}

const App = () => {
  const dispatch = useDispatch();
  const onFinish = (params) => {
    const url = new URL(window.location.href);
    if (url.searchParams.has("redirect_uri")) {
      params.redirectUri = url.searchParams.get("redirect_uri");
    }

    dispatch(loginAsExtension(params));
    return false;
  };

  return (
    <Form
      className={styles.loginForm}
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 8,
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <div className={styles.iconContainer}>
        <img src={logo} width={350} />
      </div>
      <Form.Item
        label="E-mail"
        name="email"
        rules={[
          {
            required: true,
            message: "E-mail is required",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={__("generic.fields.password")}
        name="password"
        rules={[
          {
            required: true,
            message: "Password is required",
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 8,
        }}
      >
        <Button
          type="primary"
          style={{ display: "block", width: "100%" }}
          htmlType="submit"
        >
          {__("generic.fields.login")}
        </Button>
      </Form.Item>
      {/* <Form.Item
        style={{ textAlign: "center" }}
        wrapperCol={{
          offset: 8,
          span: 8,
        }}
        className="loginFormGoogleContainer"
      >
        <GoogleLoginButton
          onSuccess={({ access_token }) => {
            const params = {
              token: access_token,
            };
            const url = new URL(window.location.href);
            if (url.searchParams.has("redirect_uri")) {
              params.redirectUri = url.searchParams.get("redirect_uri");
            }

            dispatch(loginWithGoogle(params));
          }}
        />
      </Form.Item> */}
    </Form>
  );
};

import { Checkbox, Typography } from "antd";
import React, { useEffect, useState } from "react";
import styles from "../general.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getConfig,
  selectConfig,
  selectConfigFetched,
  updateConfig,
} from "../../../session/slice";
import Loading from "../../../loading/Index";
import __ from "../../../../app/i18n";
import { Content } from "antd/lib/layout/layout";

export const NotificationsTab = () => {
  const dispatch = useDispatch();
  document.title = `${__("appName")} - ${__("team.menu.preferences.subMenu.notifications")}`;
  const config = useSelector(selectConfig);
  const configFetched = useSelector(selectConfigFetched);
  const [configState, setConfigState] = useState(config || {});

  const handleUpdateConfig = (e) => {
    setConfigState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.checked,
    }));
    dispatch(updateConfig({ [e.target.name]: e.target.checked }));
  };

  return (
    <div>
      <Typography.Title
        level={3}
        style={{ fontWeight: 400, marginTop: "2rem" }}
      >
        {__("menu.profile.tabs.notifications.label")}
      </Typography.Title>
      <div style={{ background: "#fff" }}>
        <div style={{ padding: "50px" }}>
          {!Object.keys(configState).length ? (
            <Loading fontSize={50} />
          ) : (
            <Content>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
              </div>
              <div className={styles.notificationsContainer}>
                <Checkbox
                  name="sendPostMeetingEmail"
                  checked={configState.sendPostMeetingEmail}
                  className={styles.notificationCheckbox}
                  onChange={handleUpdateConfig}
                >
                  {__("menu.profile.tabs.notifications.sendPostMeetingEmail")}
                </Checkbox>
                <Checkbox
                  name="sendPostConversationEmail"
                  checked={configState.sendPostConversationEmail}
                  className={styles.notificationCheckbox}
                  onChange={handleUpdateConfig}
                >
                  {__(
                    "menu.profile.tabs.notifications.sendPostConversationEmail"
                  )}
                </Checkbox>
                <Checkbox
                  name="sendPostCallEmail"
                  checked={configState.sendPostCallEmail}
                  className={styles.notificationCheckbox}
                  onChange={handleUpdateConfig}
                >
                  {__("menu.profile.tabs.notifications.sendPostCallEmail")}
                </Checkbox>
              </div>
            </Content>
          )}
        </div>
      </div>
    </div>
  );
};

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAppAndSearch,
  selectedAppFilters,
} from "../../meetings/filters/slice";
import { Button, Typography } from "antd";
import __ from "../../../app/i18n";
import NumberFilter from "../Filter/NumberFilter";
import OptionFilter from "../Filter/OptionFilter";

export default function Filters(props) {
  const dispatch = useDispatch();
  const appFilters = useSelector(selectedAppFilters);
  let currentValues = {};
  if (
    Object.values(appFilters).length &&
    appFilters[props.id] &&
    appFilters[props.id].values
  ) {
    currentValues = appFilters[props.id].values;
  }
  const [values, setValues] = useState(currentValues);

  const handleClick = () => {
    const appFilters = {
      [props.eventKey]: {
        values,
      },
    };

    dispatch(updateAppAndSearch({ appId: props.eventKey, appFilters }));
  };

  const handleUpdateFilter = (a, b) => {
    const v = {
      ...values,
      [a]: b,
    };

    setValues(v);
  };

  return (
    <div style={{ padding: "10px" }}>
      {[...props.properties]
        .sort((a) => (a.optionKey === "deal.dealname" ? -1 : 1))
        .map((p) => {
          if (p.optionKey === "deal.dealname") {
            return (
              <OptionFilter
                appName={props.eventKey}
                appId={props.id}
                optionKey={p.optionKey}
                options={p.options}
                label={__(`apps.${props.eventKey}.filters.${p.optionKey}`)}
                onUpdate={handleUpdateFilter}
              />
            );
          }

          if (p.type === "enum") {
            return (
              <>
                <OptionFilter
                  appName={props.eventKey}
                  appId={props.id}
                  optionKey={p.optionKey}
                  options={p.options}
                  onUpdate={handleUpdateFilter}
                  label={__(`apps.${props.eventKey}.filters.${p.optionKey}`)}
                  groupOption={
                    p.optionKey === "deal.dealstage" ? "pipelineName" : ""
                  }
                  extra={
                    <Typography.Text type="secondary">
                      {__("apps.hubspot.tab.properties.duringMeeting")}
                    </Typography.Text>
                  }
                />
                {(!Object.prototype.hasOwnProperty.call(p, 'inmutable')) ? (
                  <OptionFilter
                    appName={props.eventKey}
                    appId={props.id}
                    optionKey={"now." + p.optionKey}
                    options={p.options}
                    onUpdate={handleUpdateFilter}
                    label={__(`apps.${props.eventKey}.filters.${p.optionKey}`)}
                    groupOption={
                      p.optionKey === "deal.dealstage" ? "pipelineName" : ""
                    }
                    extra={
                      <Typography.Text type="secondary">
                        {__("apps.hubspot.tab.properties.now")}
                      </Typography.Text>
                    }
                  />
                ) : (
                  <></>
                )}
              </>
            );
          } else if (p.type === "number") {
            return (
              <>
                <NumberFilter
                  appName={props.eventKey}
                  appId={props.id}
                  optionKey={p.optionKey}
                  onUpdate={handleUpdateFilter}
                  label={__(`apps.${props.eventKey}.filters.${p.optionKey}`)}
                  extra={
                    <Typography.Text type="secondary">
                      {__("apps.hubspot.tab.properties.duringMeeting")}
                    </Typography.Text>
                  }
                />
                {(!Object.prototype.hasOwnProperty.call(p, 'inmutable')) ? (
                  <NumberFilter
                    appName={props.eventKey}
                    appId={props.id}
                    optionKey={"now." + p.optionKey}
                    label={__(`apps.${props.eventKey}.filters.${p.optionKey}`)}
                    extra={
                      <Typography.Text type="secondary">
                        {__("apps.hubspot.tab.properties.now")}
                      </Typography.Text>
                    }
                    onUpdate={handleUpdateFilter}
                  />
                ) : (
                  <></>
                )}
              </>
            );
          }
        })}
      <Button
        type="primary"
        style={{ marginTop: "5px", width: "100%" }}
        onClick={handleClick}
      >
        {__("meetings.filters.apply")}
      </Button>
    </div>
  );
}

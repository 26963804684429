import { Divider, Typography } from "antd";
import __ from "../../app/i18n";
import styles from "./styles.module.css";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectConversationDateFormated } from "./slice";

export default function Crm({ conversation }) {
  const conversationDate = useSelector(selectConversationDateFormated);
  if (!conversation || (conversation && !conversation.crm)) {
    return <></>;
  }

  let closeDate;
  if (conversation.crm.closeDate) {
    closeDate =
      moment(conversation.crm.closeDate).format("Do").replace("º", "") +
      " de " +
      moment(conversation.crm.closeDate).format("MMMM") +
      " del " +
      moment(conversation.crm.closeDate).format("YYYY");
  }

  const amount = conversation.crm.amount || "-";

  return (
    <>
      <Divider />
      <Typography.Title level={4} style={{ color: "#1791ff" }}>
        {" "}
        <span className={styles.sidebarIcon}>💰</span>
        {__("conversation.crm.label")}{" "}
        <span style={{ color: "#8c8c8c", fontSize: "12px", fontWeight: "400" }}>
          (al {conversationDate})
        </span>
      </Typography.Title>
      <Typography.Text level={4} style={{ color: "#00000073" }}>
        {conversation.crm.deal ? (
          <>
            <b>{__("conversation.crm.properties.deal")}</b>
            <br />
            {conversation.crm.deal}
            <br />
            <br />
          </>
        ) : (
          ""
        )}

        {conversation.crm.stage ? (
          <>
            <b>{__("conversation.crm.properties.stage")}</b>
            <br />
            {conversation.crm.stage}
            <br />
            <br />
          </>
        ) : (
          ""
        )}

        {amount ? (
          <>
            <b>{__("conversation.crm.properties.amount")}</b>
            <br />
            {amount}
            <br />
            <br />
          </>
        ) : (
          ""
        )}

        {conversation.crm.closeDate ? (
          <>
            <b>{__("conversation.crm.properties.closeDate")}</b>
            <br />
            {closeDate}
          </>
        ) : (
          ""
        )}

        {/* 
        <ul style={{ paddingLeft: "15px", color: "#00000073" }}>
          { conversation.crm.deal ? <li>{__('conversation.crm.properties.amount')}: {conversation.crm.deal}</li> : '' }
        </ul> */}
      </Typography.Text>
    </>
  );
}

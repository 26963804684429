export const renderMapper = (tabs) => {
  const currentTabs = [...tabs];

  return currentTabs.map((item, i) => {
    const id = String(i + 1);

    return {
      label: <span>{item.label}</span>,
      key: id,
      children: item.content,
    };
  });
};

import React from "react";
import { LoginLayout } from "../LoginLayout";
import styles from "../LoggedIn.module.css";
import { Typography } from "antd";
import __ from "../../../app/i18n";
import { useParams } from "react-router-dom";
export const ForgotPasswordEmailSent = () => {
  const { email } = useParams("email");

  return (
    <LoginLayout>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          padding: "0 50px",
          gap: "20px",
        }}
        className={styles.loginForm + " samuLoginForm"}
      >
        <img
          src="/images/emailSent.svg"
          style={{ width: "100px" }}
          alt="Forgot password email sent"
        />
        <Typography.Title level={2}>
          {__("forgotPassword.success.title")}
        </Typography.Title>
        <Typography.Text style={{ textAlign: "center" }}>
          {__("forgotPassword.success.subtitle")} <strong>{email}.</strong>
        </Typography.Text>
        <Typography.Text style={{ textAlign: "center" }}>
          {__("forgotPassword.success.description")}
        </Typography.Text>
        <Typography.Text>
          {__("forgotPassword.form.back")}{" "}
          <a href="/" className={styles.linkButton}>
            samu.ai/login
          </a>
        </Typography.Text>
      </div>
    </LoginLayout>
  );
};

import { Col, Layout, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import styles from "./LoggedIn.module.css";

export function LoginLayout({ children }) {
    return (
      <Layout>
        <Content>
          <Row>
            <Col md={12} style={{ height: "100vh", background: "#fff" }}>
              <div className={styles.loginLeftContainer}>
              {children}
              </div>
            </Col>
            <Col
              md={12}
              className={styles.loginRightContainer}
              style={{ height: "100vh", background: "#f6f6f6" }}
            >
              <div className={styles.rightContent}>
                <Row>
                  <Typography.Title className={styles.loginMainTitle}>
                    <span className={styles.markedText}>Asistente AI</span> para
                    que tu equipo venda más
                  </Typography.Title>
                </Row>
                <Row>
                  <Typography.Text className={styles.loginSubTitle}>
                    {" "}
                    El pipeline te da datos, Samu te da respuestas
                  </Typography.Text>
                </Row>
                <Row>
                  <a
                    className={styles.btnRequestDemo}
                    href="https://samu.ai/#cta_form-04-517751"
                  >
                    Solicitar una demo
                  </a>
                </Row>
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
import { Form, Input, Button } from "antd";
import { useState } from "react";
import AppItem from "../AppItem";
import __ from "../../../app/i18n";
import { sendWebhookTest } from "../slice";
import { useDispatch } from "react-redux";

function App(props) {
  const dispatch = useDispatch();
  const [url, setUrl] = useState(props.app.url);
  const [enabled, setEnabled] = useState(
    props.app.enabled
  );

  const handleSave = () => {
    const newSettings = {
      ...props.app,
      enabled,
      url
    };

    props.handleOnSave(newSettings);
  };

  const handleTest = async() => {
    dispatch(sendWebhookTest({ url }));
  };

  return (
    <AppItem app={props.app} onToggleStatus={e => setEnabled(e)} canSave={props.app} onSave={handleSave} canToggleStatus={props.app} enabled={enabled}>
      <Form>
        <Input style={{ display: "inline-block", width: "auto", minWidth: "550px", marginRight: "10px" }} onChange={e => setUrl(e.target.value)} value={url}/>
        <Button style={{ display: "inline-block" }} onClick={handleTest}>{__('apps.webhook.sendTest')}</Button>
      </Form>
    </AppItem>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { Bar, Line } from "@ant-design/plots";
import { useSelector } from "react-redux";
import { selectUsers } from "../team/users/slice";
import { toTimeFormat } from "./utils/date";
import { useForm } from "antd/lib/form/Form";
import { Empty } from "antd";

const AnalyticBar = ({
  userAnalytics,
  prop,
  className,
  avg,
  unit,
  itemKeyName,
  useTimeFormatter,
  formatter,
}) => {
  if (!itemKeyName) {
    itemKeyName = "value";
  }

  if (useTimeFormatter) {
    formatter = (item) => {
      return toTimeFormat(item[itemKeyName]);
    };
  } else {
    formatter = (v) => v;
  }

  const [data, setData] = useState([]);
  const sellers = useSelector(selectUsers);

  useEffect(() => {
    if (userAnalytics && Array.isArray(sellers) && sellers.length) {
      const data = [];

      Object.keys(userAnalytics).forEach((userId) => {
        const user = sellers.find((s) => s.id === userId);
        if (user) {
          const value = prop
            ? userAnalytics[userId][prop]
            : userAnalytics[userId];

          const item = { seller: user.name };
          item[itemKeyName] = value;

          data.push(item);
        }
      });


      setData(data.sort((a, b) => a[itemKeyName] < b[itemKeyName] ? 1 : -1));
    }
  }, [sellers, userAnalytics, itemKeyName]);

  if (!userAnalytics) {
    return <Empty/>
  }

  const config = {
    data,
    yField: "seller",
    xField: itemKeyName,
    seriesField: "seller",
    xAxis: {
      label: {
        formatter,
      },
    },
    yAxis: {
      label: {
        autoRotate: false,
      },
    },
    className,
  };

  if (avg) {
    config.annotations = [
      {
        type: "line",
        start: ["min", avg],
        end: ["max", avg],
        style: {
          stroke: "green",
          lineWidth: 2,
          lineDash: [6, 4],
        },
      },
      {
        type: "point",
        position: [avg, "max"],
        style: {
          stroke: "blue",
          fill: "blue",
          r: 5, // radius
        },
      },
      {
        type: "text",
        position: ["max", avg],
        content: `${avg}` + (unit ? unit : ""),
        offsetX: 10,
        offsetY: -5,
        style: {
          textAlign: "start",
          fill: "green",
          background: "#000",
        },
      },
      {
        type: "region",
        start: ["7", "min"],
        end: ["9", "max"],
      },
      {
        type: "dataMarker",
        position: ["2", 1216],
        text: {
          content: "2月份因逢春节水产销售需求旺盛，\n需求大增",
          style: {
            textAlign: "left",
          },
        },
        line: {
          length: 20,
        },
        point: {
          style: {
            fill: "#f5222d",
            stroke: "#f5222d",
          },
        },
        autoAdjust: false,
      },
    ];
  }

  return (
    <div>
      <Bar {...config} maxBarWidth={30} dodgePadding={20} scrollbar={false} />
    </div>
  );
};

export default AnalyticBar;

import React, {  } from "react";
import { Button } from "antd";
import whatsAppLogo from "./assets/whatsAppLogo.svg";
import { analytics } from "../../../../../hooks/tracker";

const WhatsAppTab = () => {

  const handleOnClick = () => {
    if (analytics) {
      analytics.track('WhatsApp Install Clicked');
    }
    window.open('https://chromewebstore.google.com/detail/samu-ai-extension/onjepcmleppblbdppchildjlbbgbajff?authuser=0&hl=es-419', '_blank');
  }

  return (
    <>
      <Button
        style={{ padding: "5px 10px", height: "auto" }}
        icon={
          <img
            src={whatsAppLogo}
            alt="WhatsApp"
            style={{ marginRight: "5px" }}
            width={75}
          />
        }
        onClick={handleOnClick}
      >
        <span>
          Sincronizar conversaciones
        </span>
      </Button>
    </>
  );
};

export default WhatsAppTab;

import { IconX } from '@tabler/icons-react'
import { Typography } from 'antd'
import React from 'react'
import styles from './styles.module.css'
export const OptionList = ({options, onRemoveOption}) => {
  return (
    <div className={styles.optionListContainer}>{
        options.map((option, index) => (
            <div key={option} className={styles.option}>
              <Typography.Text className={styles.optionText} type="secondary">{option}</Typography.Text>
              <IconX size={"15px"} className={styles.optionIcon} onClick={() => onRemoveOption(index)}/>
            </div>
        ))
    }</div>
  )
}

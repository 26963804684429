  import { Card, Layout, Row } from "antd";
  import { useSelector, useDispatch } from "react-redux";
  import { useNavigate, useParams, useSearchParams } from "react-router-dom";
  import { useEffect } from "react";
  import { loadMeeting, selectMeeting, selectIsDeleted, clearDeleted, loadMeetingAppTabs } from "./slice";
  import { selectIsFetching } from "../meeting/slice";
  import DealSections, { ManagerSections } from "./sections/List";
  import MeetingMedia from "./Media";
  import __ from "../../app/i18n";
  import { showGlobalMessage } from "../loading/slice";
  import MeetingDetailHeader from "./MeetingDetailHeader";

  const { Content } = Layout;

  const loadingCards = (
    <>
      <Card loading={true} style={{ marginBottom: "15px" }} />
      <Card loading={true} style={{ marginBottom: "15px" }} />
      <Card loading={true} style={{ marginBottom: "15px" }} />
      <Card loading={true} style={{ marginBottom: "15px" }} />
      <Card loading={true} style={{ marginBottom: "15px" }} />
      <Card loading={true} style={{ marginBottom: "15px" }} />
      <Card loading={true} style={{ marginBottom: "15px" }} />
    </>
  );

  export default function MeetingDetail() {
    const dispatch = useDispatch();
    const isFetching = useSelector(selectIsFetching);
    const meeting = useSelector(selectMeeting);
    const isMeetingDeleted = useSelector(selectIsDeleted);
    const { id } = useParams("id");
    const [searchParams] = useSearchParams();
    const matchTextTime = searchParams.get("time");
    const navigate = useNavigate();

    useEffect(() => {
      dispatch(loadMeeting(id));
      dispatch(loadMeetingAppTabs(id));
    }, [id]);

    useEffect(() => {
      if (!isFetching && isMeetingDeleted) {
        dispatch(showGlobalMessage(['Reunión no encontrada']));
        dispatch(clearDeleted());

        navigate('/');

        return () => {
          dispatch(clearDeleted());
        }
      }

      if (meeting && meeting.name) {
        document.title = `${__("appName")} - ${meeting.name}`;
      }
    }, [meeting, isMeetingDeleted, isFetching]);

    if (isFetching) {
      return loadingCards;
    }

    return (
      <Content style={{ padding: "0"}}>
        <Layout
          className={"site-layout-background main-layout-container"}
          style={{ padding: "0" }}
        >
          <MeetingDetailHeader/>
          <Row className="meeting-drawer-wrapper" style={{ position: 'relative'}}>
            <DealSections />
            <MeetingMedia meeting={meeting} time={matchTextTime} />
            <ManagerSections />
          </Row>
        </Layout>
      </Content>
    );
  }

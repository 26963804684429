import { Divider, Typography } from "antd";
import React from "react";
import GoogleTab from "./GoogleTab";
import { useSelector } from "react-redux";
import { selectIsMicrosoftTeamsEnabled } from "../../../../session/slice";
import MicrosoftTab from "./MicrosoftTab";
import WhatsAppTab from "./WhatsAppTab";
import __ from "../../../../../app/i18n";
import styles from "../../general.module.css";

export const IntegrationsTab = () => {
  const isMicrosoftTeamsEnabled = useSelector(selectIsMicrosoftTeamsEnabled);

  return (
    <div>
      <div>
        <Typography.Title level={5}>Google Calendar</Typography.Title>
        <GoogleTab />
      </div>
      {isMicrosoftTeamsEnabled ? (
        <>
          <Divider />
          <li>
            <Typography.Title level={5}>Microsoft Outlook</Typography.Title>
            <MicrosoftTab />
          </li>
        </>
      ) : (
        ""
      )}
      <Divider />
      <div>
        <Typography.Title level={5}>WhatsApp</Typography.Title>
        <WhatsAppTab />
      </div>
    </div>
  );
};

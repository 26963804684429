import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../../app/api";
import { showGlobalErrors } from "../../loading/slice";
import { loadInteractionCallTypes } from "../interactionCallType/slice";

const initialState = {
  status: "loading",
  teams: [],
  fetched: false,
};

export const loadTeams = createAsyncThunk(
  "teams/loadTeams",
  async (payload, { dispatch }) => {
    const response = await Api("getTeams");
    
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    return response;
  }
);

export const createTeam = createAsyncThunk(
  "teams/create",
  async (payload, { dispatch }) => {
    dispatch(startLoading());

    const response = await Api("createTeam", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    dispatch(stopLoading());
    dispatch(loadTeams());

    return response;
  }
);

export const updateTeam = createAsyncThunk(
  "teams/update",
  async (payload, { dispatch }) => {
    dispatch(startLoading());

    const response = await Api("updateTeam", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    dispatch(stopLoading());
    dispatch(loadTeams());

    return response;
  }
);

export const deleteTeam = createAsyncThunk(
  "teams/delete",
  async (payload, { dispatch }) => {
    dispatch(startLoading());
    
    const response = await Api("deleteTeam", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    dispatch(stopLoading());
    dispatch(loadTeams());
    dispatch(loadInteractionCallTypes());

    return response;
  }
);

export const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.status = "loading";
    },
    stopLoading: (state) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTeam.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createTeam.fulfilled, (state, action) => {
        state.status = 'idle';
        state.teams = [...state.teams, action.payload];
      })
      .addCase(loadTeams.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadTeams.fulfilled, (state, action) => {
        state.status = 'idle';
        state.teams = action.payload;
        state.fetched = true;
      })
      .addCase(deleteTeam.fulfilled, (state, action) => {
        state.status = 'idle';
        state.teams = state.teams.filter(team => team._id !== action.payload.id);
      });
  },
});

export const { startLoading, stopLoading } = teamsSlice.actions;

export const selectTeams = (state) => state.teams.teams;
export const selectIsFetchingTeams = (state) => state.teams.status === 'idle';
export const selectTeamsFetched = (state) => state.teams.fetched;

export default teamsSlice.reducer;

import { Select, Space } from "antd";

import { useDispatch, useSelector } from "react-redux";
import {
  selectedTeams,
  toggleHostSelection,
  updateTeamsSelection,
} from "../slice";
import { removeAccents } from "../../../../app/utils";
import __ from "../../../../app/i18n";
import { selectTeams } from "../../../team/teams/slice";

export default function TeamFilter() {
  const currentHosts = useSelector(selectedTeams);
  const allTeams = useSelector(selectTeams);
  const appliedHosts = useSelector(selectedTeams);
  const dispatch = useDispatch();

  const isSelected = (host) => {
    return currentHosts.includes(host.id);
  };

  const handleChange = (host) => {
    dispatch(toggleHostSelection(host.id));
  };

  const handleOnChange = (hosts) => {
    dispatch(updateTeamsSelection(hosts));
  };

  const options = (allTeams || []).map((item, index) => ({
    key: `team#${index}`,
    label: item.name,
    value: item._id,
  }));

  const selectProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    options,
    value: appliedHosts,
    onChange: (e) => handleOnChange(e),
    placeholder: __("meetings.filters.selectTeam"),
    showArrow: true,
    filterOption: (typed, item) => {
      const contains = String(item.label)
        .toUpperCase()
        .startsWith(String(removeAccents(typed)).toUpperCase());
      return contains;
    },
  };

  return (
    <Space
      direction="vertical"
      style={{
        width: "calc(100% - 30px)",
        margin: "15px",
      }}
    >
      <Select {...selectProps} />
    </Space>
  );
}

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Mentions,
} from "antd";
import __ from "../../../../app/i18n";
import { Option } from "antd/lib/mentions";
import { loadUsers, selectUsers } from "../../../team/users/slice";

const NewCommentBox = ({ onFinishSubmit, onSubmit, submitting }) => {
  const dispatch = useDispatch();
  const [loadedSellers, setLoadedSellers] = useState(false);
  const sellers = useSelector(selectUsers);
  const [rawComment, setRawComment] = useState("");

  // Load sellers
  useEffect(() => {
    if (
      !sellers ||
      (Array.isArray(sellers) && !sellers.length && !loadedSellers)
    ) {
      dispatch(loadUsers());
      setLoadedSellers(true);
    }
  });

  return (
    <>
      <Form.Item>
        <Mentions
          rows={4}
          value={rawComment}
          onChange={(e) => setRawComment(e)}
          placeholder=""
        >
          {(sellers || []).map((u) => (
            <Option value={u.email}>{u.name}</Option>
          ))}
        </Mentions>
      </Form.Item>
      <Form.Item>
      <Button
        htmlType="submit"
        loading={submitting}
        onClick={() => {
          onSubmit(rawComment);
          setRawComment('');
        }}
        type="primary"
      >
        {__("generic.fields.comment")}
      </Button>
      </Form.Item>
    </>
  );
};

export default NewCommentBox;

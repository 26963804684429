import { Divider, Typography } from 'antd'
import React from 'react'
import { UserCircle } from 'tabler-icons-react'
import styles from "../../styles.module.css";
import Crm from "../../Crm"
import openAiImg from "../../openai.svg";
import __ from '../../../../app/i18n';
import { useSelector } from 'react-redux';
import { selectConversation } from '../../slice';


export const SummaryTab = () => {
    const conversation = useSelector(selectConversation);
    const summary = conversation?.extractor?.samu_summary;
    const actionItems = conversation?.extractor?.samu_actionItems;
  
  return (
    <div className={styles.summaryTab}>
        <Typography.Title level={4} style={{ color: "#1791ff" }}>
          <UserCircle className={styles.sidebarIcon} />
          {__("conversation.contact")}
        </Typography.Title>
        <ul style={{ paddingLeft: "15px", color: "#00000073" }}>
          {(conversation?.contacts || []).map((item) => (
            <li>{item}</li>
          ))}
        </ul>

        <Crm conversation={conversation} />

        <Divider />

        <Typography.Title level={4} style={{ color: "#1791ff" }}>
          {" "}
          <img src={openAiImg} className={styles.sidebarIcon} alt="Open AI" />
          {__("conversation.summary")}
        </Typography.Title>
        <Typography.Text level={4} style={{ color: "#00000073" }}>
          {summary}
        </Typography.Text>

        {Array.isArray(actionItems) && actionItems.length ? (
          <>
            <Divider />
            <Typography.Title level={4} style={{ color: "#1791ff" }}>
              {" "}
              <span className={styles.sidebarIcon}>🫱🏻‍🫲🏼</span>
              {__("conversation.actionItems")}
            </Typography.Title>
            <ul style={{ paddingLeft: "15px", color: "#00000073" }}>
              {(actionItems || []).map((item) => (
                <li>{item}</li>
              ))}
            </ul>
          </>
        ) : (
          <></>
        )}

      </div>
  )
}

import { Checkbox } from "antd";

export default function Boolean({ name, description, value, onUpdateProperty }) {
  return (
    <div>
      <Checkbox
        checked={value}
        onChange={(v) => {
          onUpdateProperty(name, v.target.checked);
        }}
      >{description}</Checkbox>
    </div>
  );
}

import { Drawer, Button, Form, Input, Select, Checkbox, Tag } from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "./slice";
import __ from "../../../app/i18n";
import { appMeetingLangs, removeAccents } from "../../../app/utils";
import { selectTeams } from "../teams/slice";

export default function EditTeamUser({ open, onClose, user }) {
  const dispatch = useDispatch();
  const teams = useSelector(selectTeams);
  const [form] = Form.useForm();
  
  const [selectAllTeams, setSelectAllTeams] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState([]);

  useEffect(() => {
    if (user && teams) {
      const userTeams = teams
        .filter(team => team.users.includes(user.id || user._id))
        .map(team => team._id);

      setSelectedTeams(userTeams);
      setSelectAllTeams(userTeams.length === teams.length);
      
      form.setFieldsValue({
        name: user.uName,
        lastName: user.uLastName,
        email: user.email,
        role: String(user.role),
        lang: String(user.lang),
        teams: userTeams
      });
    }
  }, [user, teams, form]);

  if (!open || !user) return null;

  const handleSelectAllTeamsChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAllTeams(isChecked);
    const newTeams = isChecked ? teams.map(team => team._id) : [];
    setSelectedTeams(newTeams);
    form.setFieldsValue({ teams: newTeams });
  };

  const handleTeamsChange = (value) => {
    setSelectedTeams(value);
    setSelectAllTeams(value.length === teams.length);
  };

  const handleNewUser = (values) => {
    dispatch(updateUser({ 
      ...values, 
      userId: (user.id || user._id),
      teams: selectAllTeams ? teams.map(team => team._id) : selectedTeams
    }));
    onClose();
  };

  const roles = [
    __("roles.collaborator"),
    __("roles.seller"),
    __("roles.manager"),
  ];

  const langOptions = appMeetingLangs.map(lang => <Select.Option key={lang.value}>{lang.label}</Select.Option>);
  const options = [];
  roles.forEach((v, i) => {
    options.push(<Select.Option key={String(i)}>{v}</Select.Option>);
  });

  return (
    <Drawer
      title={__("team.list.editUser")}
      placement={"right"}
      width={500}
      onClose={() => onClose()}
      open={open}
      getContainer={true}
    >
      <Form
        form={form}
        name="basic"
        layout="vertical"
        onFinish={handleNewUser}
        autoComplete="off"
        initialValues={{
          name: user.uName,
          lastName: user.uLastName,
          email: user.email,
          role: String(user.role),
          lang: String(user.lang),
          teams: selectedTeams
        }}
      >
        <Form.Item
          name="name"
          label={__("generic.fields.name")}
          rules={[
            {
              required: true,
              message: __("generic.required.name"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="lastName"
          label={__("generic.fields.lastName")}
          rules={[
            {
              required: true,
              message: __("generic.required.lastName"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              message: __("generic.required.email"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label={__("team.list.role")} name="role">
          <Select defaultValue={String(user.role)}>{options}</Select>
        </Form.Item>

        <Form.Item label={__("generic.fields.lang")} name="lang">
          <Select defaultValue={String(user.lang)}>{langOptions}</Select>
        </Form.Item>

        <Form.Item style={{ marginBottom: "2px" }}>
          <Checkbox
            checked={selectAllTeams}
            onChange={handleSelectAllTeamsChange}
          >
            {__("team.newTeam.selectAllTeams")}
          </Checkbox>
        </Form.Item>

        <Form.Item
          name="teams"
          label={__("team.teams")}
        >
          <Select
            mode="multiple"
            disabled={selectAllTeams}
            onChange={handleTeamsChange}
            style={{ width: '100%' }}
            optionLabelProp="label"
            filterOption={(typed, item) => {
              const contains = String(item.label)
                .toUpperCase()
                .startsWith(String(removeAccents(typed)).toUpperCase());
              return contains;
            }}
          >
            {teams.map(team => (
              <Select.Option 
                key={team._id} 
                value={team._id}
                label={team.name}
              >
                <Tag color={team.color || 'blue'}>{team.name}</Tag>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {__("generic.fields.updateUser")}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}

import { Button, Form, Input, Typography } from 'antd'
import styles from "../LoggedIn.module.css";
import React from 'react'
import __ from '../../../app/i18n';
import { LoginLayout } from '../LoginLayout';
import { useDispatch } from 'react-redux';
import { resetPasswordByEmail } from '../../team/general/profile/slice';
import { useNavigate } from 'react-router-dom';

export const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onFinish = (params) => {
    dispatch(resetPasswordByEmail(params));
    navigate(`/forgot-password/email-sent/${params?.email}`);
  };

  return (
    <LoginLayout>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }} className={styles.loginForm + " samuLoginForm"}>
      <Typography.Title level={2}>{__("forgotPassword.form.label")}</Typography.Title>
      <Typography.Text style={{textAlign:"center"}}>{__("forgotPassword.form.description")}</Typography.Text>
      <Form
        name="basic"
        labelCol={{
          span: 12,
          offset: 0,
        }}
        wrapperCol={{}}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        style={{ paddingTop: "20px", width: "100%", maxWidth: "400px" }}
      >
        <Form.Item
          label={__("forgotPassword.form.email")}
          name="email"
          rules={[
            {
              required: true,
              message: __("forgotPassword.form.emailRequired"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%", padding: "5px", height: "auto" }}
          >
            {__("forgotPassword.form.send")}
          </Button>
        </Form.Item>
        <Form.Item
        style={{display:"flex", justifyContent:"center"}}>
          <Typography.Text>{__("forgotPassword.form.back")} {" "}
          <a href='/'
          className={styles.linkButton}
        >
           samu.ai/login
        </a>
          </Typography.Text>
        
        </Form.Item>
      </Form>
    </div>
    </LoginLayout>
  )
}

import { Drawer, Button, Form, Input, Select, Checkbox } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import __ from "../../../app/i18n";
import { createInteractionCallType } from "./slice";
import { selectTeams } from "../teams/slice";

export default function NewCallType({ open, onClose }) {
  const dispatch = useDispatch();
  const teams = useSelector(selectTeams);
  const [selectAll, setSelectAll] = useState(true);
  const [selectedTeams, setSelectedTeams] = useState(
    teams.map((team) => team._id)
  );
  const [form] = Form.useForm();

  const handleNewCallType = (values) => {
    const params = {
      name: values.name,
      description: values.description,
      teams: selectedTeams,
    };
    dispatch(createInteractionCallType(params));
    form.resetFields();
    setSelectAll(true);
    setSelectedTeams(teams.map((team) => team._id));
    onClose();
  };

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    setSelectedTeams(isChecked ? teams.map((team) => team._id) : []);
  };

  return (
    <Drawer
      title={__("team.newCallType.label")}
      zIndex={999}
      placement={"right"}
      width={500}
      onClose={() => onClose()}
      open={open}
      getContainer={true}
    >
      <Form
        form={form}
        name="basic"
        layout="vertical"
        onFinish={handleNewCallType}
        autoComplete="off"
      >
        <Form.Item
          
          name="name"
          label={__("generic.fields.name")}
          rules={[
            {
              required: true,
              message: __("generic.required.name"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label={__("team.newCallType.description")}
          rules={[
            {
              required: true,
              message: __("team.newCallType.required_description"),
            },
          ]}
        >
          <Input.TextArea rows={4} placeholder={__("team.newCallType.label_description")} />
        </Form.Item>

        <Form.Item style={{ marginBottom: "2px" }}>
          <Checkbox checked={selectAll} onChange={handleSelectAllChange}>
            {__("team.newCallType.selectAllTeams")}
          </Checkbox>
        </Form.Item>
        {!selectAll && (
          <Form.Item
            name="teams"
            label={__("team.newCallType.selectTeams")}
            rules={[
              {
                required: !selectAll,
                message: __("team.newCallType.required_teams"),
              },
            ]}
          >
            <Select
              mode="multiple"
              value={selectedTeams}
              disabled={selectAll}
              onChange={(value) => setSelectedTeams(value)}
            >
              {teams.map((team) => (
                <Select.Option key={team._id} value={team._id}>
                  {team.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {__("meetings.filters.save")}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}

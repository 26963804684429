import { CopyOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectMeeting } from "../../../slice";
import { Button, Divider, Typography, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { copyTextToClipboard, fixGPTEncoding } from "../../../../../app/utils";
import Api from "../../../../../app/api";
import BrainLoader from "../BrainLoader";
import __ from "../../../../../app/i18n";
import { analytics } from "../../../../../hooks/tracker";
import OpenAiDisclaimer from "./OpenAiDisclaimer";

const methodologyKeys = {
    spiced: {
        Situation: "situation",
        Pain: "pain",
        Impact: "impact",
        "Critical Event": "criticalEvent",
        Decision: "decision",
        "Conclusion general": "summaryConclusion",
    },
    bant: {
        Budget: "budget",
        Authority: "authority",
        Need: "need",
        Timeline: "timeline",
        "Conclusion general": "summaryConclusion",
    },
    sandler: {
        Bonding: "bonding",
        Contract: "upfrontContract",
        Pain: "painPoints",
        Budget: "budget",
        Decision: "decisionProcess",
        Fulfillment: "fulfillment",
        PostSale: "postSale",
        "Conclusion general": "summaryConclusion"
    }
}

function clearItem(text) {
    if (text) {
        return fixGPTEncoding(
            String(text).replace(/\\u([\d\w]{4})/gi, function (match, grp) {
                return String.fromCharCode(parseInt(grp, 16));
            })
        );
    }
}

const renderSection = (item, index, items) => {
    const subItems = item.split(":");
    if (index > 0 && subItems.length > 1) {
        const title = subItems[0];
        const subtitle = subItems[1];
        return (
            <li>
                <Typography.Title level={5} style={{ color: "#1791ff" }}>
                    {title}
                </Typography.Title>
                <Typography.Text type="secondary">
                    {String(subtitle).replace("- ", "ddd")}
                </Typography.Text>
            </li>
        );
    } else {
        const isListItem = String(item).startsWith("- ");
        const customStyle = isListItem ? { listStyle: "inside" } : {};

        return (
            <li style={{ marginBottom: "5px", ...customStyle }}>
                {index === 0 ? (
                    <Typography.Title level={5}>Introducción</Typography.Title>
                ) : (
                    ""
                )}

                {(index === items.length - 1 && item) ? (
                    <Typography.Title level={5}>Conclusión</Typography.Title>
                ) : (
                    ""
                )}
                <Typography.Text type="secondary">
                    {String(item).replace("- ", "")}
                </Typography.Text>
            </li>
        );
    }
};

const renderOldVersion = (text) => {
    return text
        .split("\n")
        .filter((item) => item.trim() !== "")
        .map((item, a, b) => {
            return (
                <li style={{ marginBottom: "5px" }}>
                    {renderSection(item.trim(), a, b)}
                </li>
            );
        });
};

const renderNewVersion = (obj, reportType) => {
    const keys = methodologyKeys[reportType];
    const result = [];
    for (const key in keys) {
        if (obj[keys[key]]) {
            if (key === "Conclusion general") {
                result.push(
                    <li>
                        <Divider />
                    </li>
                );
            }

            result.push(
                <li style={{ display: "inline-block", marginBottom: "10px" }}>
                    <Typography.Title level={5} style={{ color: "#1791ff" }}>
                        {key}
                    </Typography.Title>

                    <Typography.Text type="secondary">
                        {clearItem(obj[keys[key]])}
                    </Typography.Text>
                </li>
            );
        }
    }
    return result;
};

const BaseMethodologySalesSummaryTab = ({ methodologyName, reportType }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const meeting = useSelector(selectMeeting);
    const [isTriggered, setIsTriggered] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [content, setContent] = useState({});
    const contentRef = useRef();

    useEffect(() => {
        (async function () {
            if (!fetched && meeting) {
                setIsFetching(true);
                const reportData = await Api("getAIReport", {
                    id: meeting.id,
                    reportType: reportType,
                });
                setIsFetching(false);

                setFetched(true);

                if (reportData) {
                    setContent({ text: reportData.content });
                }
            }
        })();
    }, [fetched, meeting]);

    const handleCopy = () => {
        const text = contentRef.current.innerText;

        copyTextToClipboard(text);
        messageApi.open({
            type: "success",
            content: "Copiado al portapapeles",
        });
    };

    if (content && content.text) {
        return (
            <div>
                {contextHolder}
                <CopyOutlined
                    onClick={() => handleCopy()}
                    style={{
                        fontSize: "18px",
                        cursor: "pointer",
                        position: "absolute",
                        right: 0,
                    }}
                />
                <ul
                    ref={contentRef}
                    style={{ listStyle: "none", margin: 0, padding: 0 }}
                >
                    {typeof content.text === "string"
                        ? renderOldVersion(content.text)
                        : renderNewVersion(content.text, reportType)}
                </ul>
            </div>
        );
    }

    const handle = async () => {
        setIsTriggered(true);
        setIsFetching(true);

        const reportData = await Api("generateAIReport", {
            id: meeting.id,
            reportType: reportType,
        });

        if (reportData) {
            if (reportData.status === "error") {
                messageApi.open({
                    type: "error",
                    content: __("generic.errors." + reportData.code),
                });
            } else {
                setContent({ text: reportData.content });
            }
        }

        if (analytics) {
            const { id, name, client, dateFrom } = meeting;

            analytics.track(`${String(methodologyName)} Report Requested`, {
                id,
                name,
                client,
                dateFrom,
                reportData,
            });
        }

        setIsFetching(false);
    };

    if (isFetching) {
        return (
            <>
                <OpenAiDisclaimer />
                <div style={{ marginTop: "30%", textAlign: "center" }}>
                    {contextHolder}
                    <BrainLoader />
                    {isTriggered ? (
                        <div style={{ marginTop: "15px" }}>{__("ai.waitingForOpenAi")}</div>
                    ) : (
                        ""
                    )}
                </div>
            </>
        );
    }

    return (
        <>
            {contextHolder}
            <OpenAiDisclaimer />

            <Button type="primary" style={{ width: "100%" }} onClick={handle}>
                Analizar
            </Button>
        </>
    );
};

export default BaseMethodologySalesSummaryTab;

import { Col, Row, Typography } from "antd";
import styles from "../meeting/MeetingDetailHeader.module.css";
import { useSelector } from "react-redux";
import { selectConversation, selectConversationDateFormated } from "./slice";
import WhatsAppImg from "../meetings/whatsApp.svg";

export default function MeetingDetailHeader() {
  const conversation = useSelector(selectConversation);
  const conversationDate = useSelector(selectConversationDateFormated);

  if (conversation) {
    return (
      <Row className={styles.container + " meeting-header-detail-container"}>
        <Col sm={15}>
          <img src={WhatsAppImg} style={{ width: "100px", marginRight: "15px" }} alt="WhatsApp" />
          <span className={styles.textContainer}>
            <Typography.Title
              style={{ color: "#1791ff" }}
              level={2}
              className={styles.meetingName}
            >
              {conversation.name}
            </Typography.Title>
            <Typography.Text className={styles.meetingDate} type="secondary">
              {conversationDate}
            </Typography.Text>
          </span>
        </Col>
      </Row>
    );
  } else {
    return <></>;
  }
}
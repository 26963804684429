import { Button, Form, Input, Divider } from "antd";
import { login, loginWithGoogle } from "./slice";
import styles from "./LoggedIn.module.css";
import { useDispatch } from "react-redux";
import __ from "../../app/i18n";
import logo from "../../brand/Isologotype/samu_light_bckgrnd.png";
import GoogleLoginButton from "./GoogleLoginButton";
import { LoginLayout } from "./LoginLayout";
import { trimObjectValues } from "./utils";

export default function LoginForm() {
  return (
    <LoginLayout>
      <App />
    </LoginLayout>
  );
}

const App = () => {
  const dispatch = useDispatch();

  const onFinish = (params) => {
    const loginData = trimObjectValues(params);
    dispatch(login(loginData));

    return false;
  };

  return (
    <Form
      className={styles.loginForm + " samuLoginForm"}
      name="basic"
      labelCol={{
        span: 12,
        offset: 0,
      }}
      wrapperCol={{}}
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
    >
      <div className={styles.iconContainer}>
        <img src={logo} width={350} alt="Samu.ai logo" />
      </div>
      <Form.Item
        label="E-mail"
        name="email"
        rules={[
          {
            required: true,
            message: "E-mail is required",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={__("generic.fields.password")}
        name="password"
        rules={[
          {
            required: true,
            message: "Password is required",
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <a href="/forgot-password">
          {__("generic.fields.forgotPassword")}
        </a>
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 0,
          span: 24,
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          className={styles.btnSamuLogin}
          style={{ width: "100%", padding: "5px", height: "auto" }}
        >
          {__("generic.fields.login")}
        </Button>
      </Form.Item>
      <Divider />

      <Form.Item
        style={{ textAlign: "center" }}
        wrapperCol={{
          offset: 0,
          span: 24,
        }}
        className="loginFormGoogleContainer"
      >
        <GoogleLoginButton
          onSuccess={({ access_token }) => {
            dispatch(loginWithGoogle({ token: access_token }));
          }}
        />
      </Form.Item>
    </Form>
  );
};

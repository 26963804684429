import { Divider, Select, Typography } from "antd";
import React, { useState } from "react";
import __ from "../../../../../app/i18n";
import ChangePasswordSection from "./ChangePasswordSection";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../../session/slice";
import { updateUser } from "../../../../team/users/slice";
import { appMeetingLangs } from "../../../../../app/utils";
import styles from "../../general.module.css";

export const ProfileDetailTab = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [lang, setLang] = useState(user.lang);

  const handleLangUpdate = (l) => {
    setLang(l);
    dispatch(updateUser({ lang: l, userId: user.id || user._id }));
  };

  const langOptions = appMeetingLangs.map((lang) => ({
    value: lang.value,
    label: lang.label,
  }));

  return (
    <div>
        <div>
          <Typography.Title level={5}>
            {__("generic.fields.name")}
          </Typography.Title>
          <Typography.Text>{user.name}</Typography.Text>
        </div>
        <Divider className={styles.divider} />
        <div>
          <Typography.Title level={5}>
            {__("generic.fields.lastName")}
          </Typography.Title>
          <Typography.Text>{user.lastName}</Typography.Text>
        </div>
        <Divider className={styles.divider}  />
        <div>
          <Typography.Title level={5}>
            {__("generic.fields.email")}
          </Typography.Title>
          <Typography.Text>{user.email}</Typography.Text>
        </div>
        <Divider className={styles.divider}  />
        <div>
          <Typography.Title level={5}>
            {__("generic.fields.lang")}
          </Typography.Title>
          <Typography.Text
            style={{
              display: "block",
              marginTop: "-10px",
              marginBottom: "15px",
            }}
            type="secondary"
          >
            {__("generic.fields.langUsage")}
          </Typography.Text>

          <Select
            options={langOptions}
            value={__(`lang.${String(lang).toLowerCase()}`)}
            onChange={(e) => handleLangUpdate(e)}
            style={{ minWidth: "100px" }}
          />
        </div>
        <Divider className={styles.divider}  />
        <div>
          <Typography.Title level={5}>
            {__("generic.fields.password")}
          </Typography.Title>
          <ChangePasswordSection />
        </div>
    </div>
  );
};

import React from "react";
import { Form, Input } from "antd";

const ConfirmPassword = ({ labels }) => {
  return (
    <>
      <Form.Item
        label={labels.password}
        name="password"
        rules={[
          {
            required: true,
            message: labels.fieldRequired,
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label={labels.confirmPassword}
        name="confirmPassword"
        dependencies={["password"]}
        rules={[
          {
            required: true,
            message: labels.fieldRequired,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(labels.confirmPasswordMatchError)
              );
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
    </>
  );
};

export default ConfirmPassword;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../../../app/api';
import { createCallType } from './calltypes/slice';

const initialState = {
  hosts: [],
  status: 'idle',
  isFetching: false,
  hasFiltersApplied: false,
  appFiltersFetched: false,
  callTypes: [],
  appliedFilters: {
    callType: {},
    hosts: [],
    teams: [],
    sources: [],
    words: null,
    dateFrom: null,
    dateTo: null,
    clients: [],
    speakers: [],
    trackers: [],
    libraries: [],
    tags: [],
    excludeTags: [],
    duration: { from: 0, updated: false },
    samuScore: { from: 0, updated: false },
    seen: null,
    appFilters: []
  },
  meetings: [],
  appFilters: [],
  pagination: {
    total: 0,
    page: 1,
    perPage: 10
  }
};

export const getHosts = createAsyncThunk(
  'filters/getHosts',
  async () => {
    try {
      const hosts = await API('getHosts');

      return hosts;
    } catch (error) {

    }

    return []
  }
);
export const loadAppFilters = createAsyncThunk(
  'filters/getAppFilters',
  async () => {
    try {
      const d = await API('getAppFilters');

      return d;
    } catch (error) {
    }

    return []
  }
);

export const searchMeetings = createAsyncThunk(
  'filters/searchMeetings',
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const req = { ...state.filters.appliedFilters, ...state.filters.pagination };
    const meetings = await API('searchMeetings', req);
    return meetings;
  }
);
export const updateMeetingHost = createAsyncThunk(
  'filters/updateHost',
  async (payload, { getState, dispatch }) => {
    dispatch(markUpdatingHost(payload));

    await API('updateHost', payload);

    return payload;
  }
);

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateFilters: (state, action) => {
      state.appliedFilters = { ...state.appliedFilters, ...action.payload };
      state.hasFiltersApplied = true;
    },

    updatePagination: (state, action) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },

    clearHasFiltersApplied: (state) => {
      state.appliedFilters = initialState.appliedFilters;
      state.hasFiltersApplied = false;
    },

    setWordsFilter: (state, action) => {
      state.appliedFilters.words = action.payload;
    },
    updateMeetingTags: (state, action) => {
      const meetings = [...state.meetings];
      const meeting = meetings.find(m => m.id === action.payload.meetingId);
      if (meeting) {
        meeting.tags = action.payload.tags;
      }

      state.meetings = meetings;
    },
    markUpdatingHost: (state, action) => {
      const meetings = [...state.meetings];
      const meeting = meetings.find(m => m.id === action.payload.meetingId);

      if (meeting) {
        meeting.isHostUpdating = true;
      }

      state.meetings = meetings;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getHosts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getHosts.fulfilled, (state, action) => {
        state.status = 'idle';
        state.hosts = action.payload;
      })
      .addCase(searchMeetings.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(searchMeetings.fulfilled, (state, action) => {
        state.isFetching = false;
        state.meetings = action.payload.items;
        state.pagination.total = action.payload.total;
        state.pagination.page = action.payload.page;
        state.pagination.perPage = action.payload.perPage;
      })
      .addCase(updateMeetingHost.fulfilled, (state, action) => {
        state.isFetching = false;
        const meetings = [...state.meetings];
        const meeting = meetings.find(m => m.id === action.payload.meetingId);

        if (meeting) {
          meeting.host = action.payload.hostName;
          meeting.isHostUpdating = false;
        }

        state.meetings = meetings;
      })

      .addCase(loadAppFilters.fulfilled, (state, action) => {
        state.appFilters = action.payload;
        state.appFiltersFetched = true;
      })
  },
});

export const { updateFilters, clearHasFiltersApplied, updateMeetingTags, markUpdatingHost, updatePagination } = filtersSlice.actions;

export const isLoading = (state) => state.filters.status === 'loading';
export const isFetching = (state) => state.filters.isFetching;
export const selectIsMeetingListReady = (state) => !state.filters.isFetching;
export const hosts = (state) => state.filters.hosts;
export const teams = (state) => state.filters.teams;
export const selectedHosts = (state) => state.filters.appliedFilters.hosts;
export const selectedTeams = (state) => state.filters.appliedFilters.teams;
export const selectedSources = (state) => state.filters.appliedFilters.sources;
export const selectedClients = (state) => state.filters.appliedFilters.clients;
export const selectedTrackers = (state) => state.filters.appliedFilters.trackers;
export const selectedLibraries = (state) => state.filters.appliedFilters.libraries;
export const selectedTags = (state) => state.filters.appliedFilters.tags;
export const selectedExcludedTags = (state) => state.filters.appliedFilters.excludedTags;
export const selectedSpeakers = (state) => state.filters.appliedFilters.speakers;
export const selectedWords = (state) => state.filters.appliedFilters.words;
export const selectedAppFilters = (state) => state.filters.appliedFilters.appFilters;
export const selectedName = (state) => state.filters.appliedFilters.name;
export const selectedDates = (state) => ([state.filters.appliedFilters.dateFrom, state.filters.appliedFilters.dateTo])
export const selectedFilters = (state) => state.filters.appliedFilters;
export const selectedDuration = (state) => state.filters.appliedFilters.duration;
export const selectedSamuScore = (state) => state.filters.appliedFilters.samuScore;
export const selectedSeen = (state) => state.filters.appliedFilters.seen;
export const selectedCallType = (state) => state.filters.appliedFilters.callType;
export const selectFiltersApplied = (state) => state.filters.appliedFilters;
export const filtersState = (state) => state;
export const meetings = (state) => state.filters.meetings;
export const selectSearchPagination = (state) => state.filters.pagination;
export const selectHasFiltersApplied = (state) => state.filters.hasFiltersApplied;
export const selectAppFilters = (state) => state.filters.appFilters;

export default filtersSlice.reducer;

export const updateAndSearch = (payload) => (dispatch, getState) => {
  dispatch(updateFilters(payload));

  dispatch(searchMeetings())
};

export const updatePageAndSearch = (payload) => (dispatch, getState) => {
  dispatch(updatePagination(payload));

  dispatch(searchMeetings())
};

export const updateAppAndSearch = (payload) => (dispatch, getState) => {
  dispatch(updateFilters(payload));
  dispatch(searchMeetings())
};

export const filterByDate = ({ dateFrom, dateTo }) => (dispatch) => {
  dispatch(updateFilters({ dateFrom, dateTo }));
  dispatch(searchMeetings())
};

export const clearFilters = () => (dispatch) => {
  dispatch(updateFilters(initialState));
  dispatch(clearHasFiltersApplied());
  dispatch(searchMeetings())
};

export const toggleHostSelection = (hostId) => (dispatch, getState) => {
  const state = getState();
  let hosts = [...state.filters.appliedFilters.hosts];

  if (hosts.includes(hostId)) {
    hosts = hosts.filter(host => host !== hostId);
  } else {
    hosts.push(hostId)
  }

  dispatch(updateFilters({ hosts }));
  dispatch(searchMeetings())
};

export const toggleTeamSelection = (teamId) => (dispatch, getState) => {
  const state = getState();
  let teams = [...state.filters.appliedFilters.teams];

  if (teams.includes(teamId)) {
    teams = hosts.filter(host => host !== teamId);
  } else {
    teams.push(teamId)
  }

  dispatch(updateFilters({ teams }));
  dispatch(searchMeetings())
};

export const updateDurationFilter = ({ from, to }) => (dispatch, getState) => {
  dispatch(updateFilters({ duration: { from, to, applied: true } }));
  dispatch(searchMeetings())
};

export const updateSamuScoreFilter = ({ from, to }) => (dispatch, getState) => {
  dispatch(updateFilters({ samuScore: { from, to, applied: true } }));
  dispatch(searchMeetings())
};

export const updateLibrariesSelection = (libraries) => (dispatch, getState) => {
  dispatch(updateFilters({ libraries }));
  dispatch(searchMeetings())
};

export const updateTagsSelection = (tags) => (dispatch, getState) => {
  dispatch(updateFilters({ tags }));
  dispatch(searchMeetings())
};

export const updateTagsExcludeSelection = (excludeTags) => (dispatch, getState) => {
  dispatch(updateFilters({ excludeTags }));
  dispatch(searchMeetings())
};

export const toggleTrackersSelection = (trackerId) => (dispatch, getState) => {
  const state = getState();
  let trackers = [...state.filters.appliedFilters.trackers];

  if (trackers.includes(trackerId)) {
    trackers = trackers.filter(tracker => tracker !== trackerId);
  } else {
    trackers.push(trackerId)
  }

  dispatch(updateFilters({ trackers }));
  dispatch(searchMeetings())
};

export const toggleClientSelection = (clientId) => (dispatch, getState) => {
  const state = getState();
  let clients = [...state.filters.appliedFilters.clients];

  if (clients.includes(clientId)) {
    clients = clients.filter(client => client !== clientId);
  } else {
    clients.push(clientId)
  }

  dispatch(updateFilters({ clients }));
  dispatch(searchMeetings())
};


export const updateSpeakersSelection = (speakers) => (dispatch, getState) => {
  dispatch(updateFilters({ speakers }));
  dispatch(searchMeetings())
};


export const updateClientsSelection = (clients) => (dispatch, getState) => {
  dispatch(updateFilters({ clients }));
  dispatch(searchMeetings())
};

export const updateHostsSelection = (hosts) => (dispatch, getState) => {
  dispatch(updateFilters({ hosts }));
  dispatch(searchMeetings())
};

export const updateTeamsSelection = (teams) => (dispatch, getState) => {
  dispatch(updateFilters({ teams }));
  dispatch(searchMeetings())
};

export const updateSourcesSelection = (sources) => (dispatch, getState) => {
  dispatch(updateFilters({ sources }));
  dispatch(searchMeetings())
};

export const updateSeenSelection = (seen) => (dispatch, getState) => {
  dispatch(updateFilters({ seen }));
  dispatch(searchMeetings())
};

export const updateCallTypeSelecion = (selectedCallType) => (dispatch, getState) => {
  dispatch(updateFilters({ ...selectedCallType.filters, callType: selectedCallType }));
  dispatch(searchMeetings())
};

export const createCallTypeFromFilters = (callTypeProps) => (dispatch, getState) => {
  const { name } = callTypeProps;
  const currentState = getState();
  const payload = {
    name,
    filters: currentState.filters.appliedFilters
  };

  dispatch(createCallType(payload));
  dispatch(updateFilters({ callType: { name } }));
};
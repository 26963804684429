import { useSelector } from "react-redux";
import React, { useMemo, useState } from "react";
import { Button, Input } from "antd";
import { selectMeeting } from "../../slice";
import __ from "../../../../app/i18n";
import TranscriptionList from "./TranscriptionList";
import Section from "../Section";
import { DownloadOutlined } from '@ant-design/icons';
import moment from "moment";
import styles from './styles.module.css';

const formatTranscription = (transcription) => {
  return `${transcription.who}: ${transcription.text}`;
};

const SearchList = () => {
  const meeting = useSelector(selectMeeting);
  const [q, setQ] = useState("");
  const [transcription, setTranscription] = useState([]);

  const transcriptionFile = useMemo(() => {
    return transcription.map(formatTranscription).join('\n');
  }, [transcription]);

  const fileName = useMemo(() => {
    const baseFileName = __("meetings.details.sections.transcription.fileName");
    const sanitizedMeetingName = meeting?.name?.replace(/\s/g, "_");
    const formattedDate = moment(meeting?.dateFrom).format('DD-MM-YYYY');
    return `${baseFileName}_${sanitizedMeetingName}_${formattedDate}.txt`;
  }, [meeting]);

  const handleDownloadTranscription = () => {
    const file = new Blob([transcriptionFile], { type: 'text/plain' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(link.href);
  }


  return (
    <Section
      title={__("meetings.details.sections.transcription.search")}
      sectionKey={"search"}
    >
      <div>
        <div className={styles.transcriptionContainer}>
          <Input.Search
            placeholder={__("meetings.details.sections.transcription.search")}
            onSearch={(e) => setQ(e)}
            style={{}}
          />
          <Button type="primary" onClick={handleDownloadTranscription} icon={<DownloadOutlined />} size='middle' />
        </div>

        <TranscriptionList onGetTranscription={(transcription) => setTranscription(transcription)} transcription={transcription} meeting={meeting} q={q} onClear={() => setQ('')} />
      </div>
    </Section>
  );
};

export default SearchList;

import { Button, Form, Typography } from "antd";
import { logout, setPassword } from "./slice";
import styles from "./LoggedIn.module.css";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import __ from "../../app/i18n";
import { useEffect } from "react";
import { LoginLayout } from "./LoginLayout";
import ConfirmPassword from "./ConfirmPassword";

export default function SetPassword() {
  return (
    <LoginLayout>
      <App />
    </LoginLayout>
  );
}

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { passwordToken } = useParams();

  useEffect(() => {
    dispatch(logout());
  }, []);

  const onFinish = (params) => {
    dispatch(setPassword({ password: params.password, createPasswordToken: decodeURIComponent(passwordToken) }));

    return navigate('/meetings');
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
      className={styles.loginForm + " samuLoginForm"}
    >
      <Typography.Title level={2}>
        {__("generic.fields.changePassword")}
      </Typography.Title>
      <Form
        name="basic"
        labelCol={{
          span: 12,
          offset: 0,
        }}
        wrapperCol={{}}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        style={{ width: "100%", maxWidth: "400px" }}
      >
        <ConfirmPassword
          labels={{
            password: "Contraseña",
            confirmPassword: "Confirmar contraseña",
            fieldRequired: "El campo es requerido",
            confirmPasswordMatchError: "Las contraseñas no coinciden",
          }}
          onSubmit={onFinish}
        />

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 8,
          }}
        >
          <Button type="primary" htmlType="submit">
            {__("generic.fields.setPassword")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

import React, { useState } from "react";
import { OptionList } from "./OptionList";
import { Button, Input } from "antd";
import { IconPlus } from "@tabler/icons-react";
import styles from "./styles.module.css";
import __ from "../../../app/i18n";

export const OptionSelect = ({ options, onRemove, onNew }) => {
  const [optionInputValue, setOptionInputValue] = useState("");

  return (
    <div className={styles.optionSelect}>
      <OptionList options={options} onRemoveOption={onRemove} />
      <div className={styles.optionSelectContainer}>
        <Input
          className={styles.optionInput}
          value={optionInputValue}
          onChange={(e) => setOptionInputValue(e.target.value)}
        />
        <Button
          type="primary"
          className={styles.optionButton}
          icon={
            <IconPlus size="20px" stroke={2} className={styles.optionSelectIcon} />
          }
          onClick={() => {
            onNew(optionInputValue.trim());
            setOptionInputValue("");
          }}
        >
          {__("apps.extractor.generic.option.add")}
        </Button>
      </div>
    </div>
  );
};
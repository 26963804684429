import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadCallTypes, selectCallTypesFetched } from "../../meetings/filters/calltypes/slice";
import { Select } from "antd";
import { removeAccents } from "../../../app/utils";
import __ from "../../../app/i18n";
import { selectUsers } from "../../team/users/slice";
import styles from '../analytics.module.css';
import * as primarySlice from "../slice";
import * as secondarySlice from "../Compare/slice";

export default function UserCallTypeFilter ({ isSecondary } = { isSecondary: false }) {
  const { updateUserIdsFilters } = isSecondary ? secondarySlice : primarySlice;

  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const callTypeFetched = useSelector(selectCallTypesFetched);
  const [value, setValue] = useState([]);
  const [allowClear, setAllowClear] = useState(false);

  useEffect(() => {
    if (!callTypeFetched) {
      dispatch(loadCallTypes());
    }
  }, [callTypeFetched]);

  useEffect(() => {
    setAllowClear(!value?.label);
  }, [value])

  const options = (users || []).map((item, index) => ({
    key: `user#${index}`,
    label: item.name,
    value: item.id,
  })).sort((a, b) => a.label > b.label ? 1 : -1)

  const handleOnChange = (userIds) => {
    let payload;
    if (userIds && userIds.length) {
      payload = userIds.filter(id => !!id);
      setValue(payload);
    } else {
      setValue([]);
    }

    dispatch(updateUserIdsFilters(payload));
    return true;
  };

  const selectProps = {
    mode: "multiple",
    options,
    value: value || [],
    onChange: (e) => handleOnChange(e),
    placeholder: 'Seleccione vendedores',
    showArrow: true,
    filterOption: (typed, item) => {
      const sellerName = removeAccents(String(item.label).toUpperCase());
      const contains = sellerName.startsWith(String(removeAccents(typed)).toUpperCase())
      return contains;
    },
    onClear: (e) => handleOnChange(e),
    className: styles.analyticsFilterSelect
  };

  return <div className={styles.analyticsFilterSelectorContainer}>
    <span className={styles.analyticsFilterFilterTitle}>Vendedores</span>
    <Select {...selectProps} allowClear={allowClear}/>
  </div>
}
import { CopyOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectMeetingMinute } from "../../../slice";
import { Typography, message } from "antd";
import { useRef, useState } from "react";
import { copyTextToClipboard } from "../../../../../app/utils";

const MinuteTab = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [content, setContent] = useState({});
  const contentRef = useRef();
  const minute = useSelector(selectMeetingMinute);

  const handleCopy = () => {
    const text = contentRef.current.innerText;

    copyTextToClipboard(text);
    messageApi.open({
      type: "success",
      content: "Copiado al portapapeles",
    });
  };

  return (
    <div>
      {contextHolder}
      <CopyOutlined
        onClick={() => handleCopy()}
        style={{
          fontSize: "18px",
          cursor: "pointer",
          position: "absolute",
          right: 0,
        }}
      />
      <ul ref={contentRef} style={{ listStyle: "none", margin: 0, padding: 0 }}>
        <li style={{ marginBottom: "15px" }}>
          <Typography.Title level={5} style={{ color: "#1791ff" }}>
            Resumen general
          </Typography.Title>
          <Typography.Text type="secondary">{minute.summary}</Typography.Text>
        </li>

        <li style={{ marginBottom: "15px" }}>
          <Typography.Title level={5} style={{ color: "#1791ff" }}>
            Probabilidad de avance
          </Typography.Title>
          <Typography.Text type="secondary">
            {" "}
            <span style={{ color: "orange" }}>
              {"★".repeat(minute.probKey) + "☆".repeat(5 - minute.probKey)}
            </span>
            {minute.probDesc && String(minute.probDesc).trim().length ? (
              <div>{minute.probDesc}</div>
            ) : (
              ""
            )}
          </Typography.Text>
        </li>

        {minute.actionItems &&
        Array.isArray(minute.actionItems) &&
        minute.actionItems.length ? (
          <>
            <li style={{ marginBottom: "15px" }}>
              <Typography.Title level={5} style={{ color: "#1791ff" }}>
                🫱🏻‍🫲🏼 Compromisos
              </Typography.Title>

              <ul>
                {minute.actionItems.map((o) => {
                  return (
                    <li>
                      <span style={{ color: "#00000073" }}>
                        {o}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </li>
          </>
        ) : (
          ""
        )}

        {minute.competences && String(minute.competences).trim().length ? (
          <li style={{ marginBottom: "15px" }}>
            <Typography.Title level={5} style={{ color: "#1791ff" }}>
              Competidores mencionadas
            </Typography.Title>
            <Typography.Text type="secondary">
              {minute.competences}
            </Typography.Text>
          </li>
        ) : (
          ""
        )}

        {minute.objections &&
        Array.isArray(minute.objections) &&
        minute.objections.length ? (
          <>
            <li style={{ marginBottom: "15px" }}>
              <Typography.Title level={5} style={{ color: "#1791ff" }}>
                Objeciones
              </Typography.Title>

              <Typography.Text
                level={5}
                style={{ color: "#1791ff", marginBottom: "10px" }}
              >
                ✅ - Manejada correctamente | 🚫 - No manejada correctamente |
                🤔 - Oportunidad de mejora
              </Typography.Text>

              <ul>
                {minute.objections.map((o) => {
                  let icon;

                  let answered = String(o.answered).toUpperCase();
                  if (answered.startsWith("S")) {
                    icon = "✅";
                  } else if (answered === "NO") {
                    icon = "🚫";
                  } else {
                    icon = "🤔";
                  }

                  return (
                    <li>
                      {icon} |{" "}
                      <span style={{ color: "#00000073" }}>
                        {o.description}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </li>
          </>
        ) : (
          ""
        )}
      </ul>
    </div>
  );
};

export default MinuteTab;
